import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router';

import config from 'inkp-config';
import { Cart, SHIPPING_SPEED_ENUM } from 'inkp-order-sdk/types.g';
import { User } from 'inkp-user-sdk/types.g';
import { Product } from 'inkp-product-sdk/types.g';
import { formatToPhone } from 'inkp-user-sdk/user';
import { path, routes } from 'inkp-routes/public';

import Logo from 'inkp-components/dist/Components/Logo';
import MobileSlideModal from 'inkp-components/dist/Components/MobileSlideModal';

import { GetDeliverByDate } from '../../../util/Product';
import { HeaderButton, MobileHeaderButton } from '../../../components/HeaderButton';
import Contact from './Contact';
import Shipping from './Shipping';
import Payment from './Payment';
import OrderSummary from '../OrderSummary';
import { CHECKOUT_STEPS, CHECKOUT_STEP_MAP, ShippingCodeMapping } from '../constants';
import { calculateCartProductQuantity } from '../../../util/Checkout';

import GTM from '../../../util/gtm';
import { GTMTypes, GTMEvents } from '../../../interfaces/GTM';


const formatDate = (date: string): string => {
  return moment(new Date(date)).format('ddd, MMM D');
};

interface Props extends RouteComponentProps {
  cart: Cart;
  products: Product[];
  user: User;
}

function StepCard(props: {
  step: number,
  isCompleted: boolean,
  title: string,
  contentFirstCol?: React.ReactNode,
  contentSecondCol?: React.ReactNode,
  onClick: () => void,
}) {
  const { step, isCompleted, title, contentFirstCol, contentSecondCol, onClick } = props;
  const iconClassName = `
    fs-icon-1p5
    ${isCompleted ? 'color-green' : 'color-navy-700'}
    mdi mdi-${isCompleted ? 'check' : ('numeric-' + step)}-circle
  `;

  return (
    <div className={`${isCompleted ? 'cursor-pointer' : ''}`} onClick={isCompleted ? onClick : undefined}>
      <div className="checkout-form flex md:w-3/4 mx-auto px-1 md:px-0 py-1p5">
        <div className="flex-none">
          {isCompleted ? (
            <i className={iconClassName} />
          ) : (
            <span className="d-ib br-full bgc-navy-700 color-white ta-center fw-bold w-1p5 h-1p5">{step}</span>
          )}
        </div>
        <div className="flex-grow ml-p5">
          <div className="color-navy-700 fw-bold">{title}</div>
          {isCompleted && (
            <div className="md:flex fs-xs mt-p25 -mx-p25">
              <div className="flex-1 px-p25">
                {contentFirstCol}
              </div>
              <div className="flex-1 px-p25 mt-p75 md:mt-0">
                {contentSecondCol}
              </div>
            </div>
          )}
        </div>
        {isCompleted && <div className="flex-none cursor-pointer color-navy-700 fw-bold">Edit</div>}
      </div>
    </div>
  );
}

function Header(props: {
  itemCount: number;
  onShowCartSummary: () => void;
}) {
  const { itemCount, onShowCartSummary } = props;
  return (
    <>
      <div className="d-n md:d-b">
        <div className="flex items-center h-3p5">
          <div className="flex-1 pl-1p5 flex items-center">
            <Logo id="desktop" className="d-b" width="125px" height="32px" />
          </div>
          <div className="fs-xl fw-extra-bold flex-none">Checkout</div>
          <div className="flex-1 flex justify-end">
            <HeaderButton className="color-navy-800 -ml-1p25" icon="headset" href={`tel:${config.contact.phone}`}>
              <div className="color-primary">
                <style jsx>
                  {`
                    div {
                      font-size: 10px;
                      line-height: 15px;
                      height: 15px;
                    }
                  `}
                </style>
                Talk to a Specialist
              </div>
              <div className="fs-md-n">
                {formatToPhone(config.contact.phone)}
              </div>
            </HeaderButton>
          </div>
        </div>
      </div>

      <div className="md:d-n px-1">
        <div className="flex items-center h-3p5">
          <div>
            <Logo id="mobile" className="d-b" type="logo-only" width="36px" height="32px" />
          </div>
          <div className="fs-lg fw-extra-bold ml-1">Checkout</div>
          <div className="flex-1 flex color-primary justify-end">
            <MobileHeaderButton
              className="color-navy-800"
              text="Help"
              icon="headset"
              href={`tel:${config.contact.phone}`}
            />
            <MobileHeaderButton
              className="color-navy-800"
              text={`${itemCount} ${itemCount > 1 ? 'Items' : 'Item'}`}
              icon="receipt"
              onClick={() => onShowCartSummary()}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function Checkout(props: Props) {
  const { products, user, cart } = props;
  const cartQuantity = calculateCartProductQuantity(cart);

  const [showCartDrawer, setShowCartDrawer] = React.useState(false);

  const [step, setStep] = React.useState(CHECKOUT_STEPS.CONTACT);
  const [completedMap, setCompletedMap] = React.useState({
    contact: false,
    shipping: false,
    payment: false,
  }); 
  
  React.useEffect(() => {
    GTM.push(GTMTypes.USER);
    GTM.push(GTMTypes.CART);
    GTM.fire(GTMEvents.INITIATE_CHECKOUT, { checkoutStep: step + 1 }); // CHECKOUT_STEPS is 0-based
  }, [step])


  const nextStep = (data?: { orderId: string }) => {
    if (step === CHECKOUT_STEPS.PAYMENT) {
      if (!data || !data.orderId) throw new Error('orderId is not defined');
      props.history.push(path(routes.app.order.confirmation, { orderId: data.orderId }));
      return;
    }
    setCompletedMap({...completedMap, [CHECKOUT_STEP_MAP[step]]: true });
    setStep((step + 1) as CHECKOUT_STEPS);
  }

  return (
    <div className="flex">
      <div className="flex-grow bc-navy-200 minh-full md:bwr-1 pb-7">
        <style jsx global>
          {`
            .checkout-form {
              max-width: 507px;
            }
            .next-step-button {
              width: 253px;
            }
          `}
        </style>
        <Header
          itemCount={cartQuantity}
          onShowCartSummary={() => setShowCartDrawer(true)}
        />

        <div className="bwt-1 bc-navy-200" />

        {step === CHECKOUT_STEPS.CONTACT ? (
          <div className="bgc-blue-50">
            <div className="checkout-form md:w-3/4 mx-auto py-3">
              <Contact cart={cart} user={user} onNext={nextStep} />
            </div>
          </div>
        ) : (
          <StepCard
            step={1}
            isCompleted={completedMap.contact}
            title="Contact Info"
            contentFirstCol={completedMap.contact ? (
              <div>
                {user.name}<br />
                {user.email}<br />
                {user.phone && formatToPhone(user.phone)}
              </div>
            ) : undefined}
            contentSecondCol={completedMap.contact ? (
              <div>
                Artwork Approval: {_.get(cart, 'features.requiresCustomerApproval') ? 'Yes' : 'No'}
              </div>
            ) : undefined}
            onClick={() => setStep(CHECKOUT_STEPS.CONTACT)}
          />
        )}

        <div className="bwt-1 bc-navy-200" />

        {step === CHECKOUT_STEPS.SHIPPING ? (
          <div className="bgc-blue-50">
            <div className="checkout-form md:w-3/4 mx-auto py-3">
              <Shipping cart={cart} user={user} onNext={nextStep} />
            </div>
          </div>
        ) : (
          <StepCard
            step={2}
            isCompleted={completedMap.shipping}
            title="Shipping Info"
            contentFirstCol={completedMap.shipping ? (
              <div>
                {_.get(cart, 'shippingAddress.name')}<br />
                {`${_.get(cart, 'shippingAddress.address1')}, ` || ''}{_.get(cart, 'shippingAddress.address2') || ''}<br />
                {`${_.get(cart, 'shippingAddress.city')}, ` || ''}{`${_.get(cart, 'shippingAddress.state')}, ` || ''}
                {`${_.get(cart, 'shippingAddress.zip')}, ` || ''}{_.get(cart, 'shippingAddress.country') || 'US'}
              </div>
            ) : undefined}
            contentSecondCol={completedMap.shipping ? (
              <div>
                {(() => {
                  const speed = _.get(cart, 'shippingDetails.speed');
                  if (!speed) return null;
                  return (
                    <>
                      {ShippingCodeMapping[speed] || ''}<br />
                      Delivered by {formatDate(GetDeliverByDate(new Date().toString(), speed))}
                    </>
                  )
                })()}
              </div>
            ) : undefined}
            onClick={() => setStep(CHECKOUT_STEPS.SHIPPING)}
          />
        )}

        <div className="bwt-1 bc-navy-200" />

        {step === CHECKOUT_STEPS.PAYMENT ? (
          <div className="bgc-blue-50">
            <div className="checkout-form md:w-3/4 mx-auto py-3">
              <Payment cart={cart} user={user} onNext={(orderId) => nextStep({ orderId })}/>
            </div>
          </div>
        ) : (
          <StepCard
            step={3}
            isCompleted={completedMap.payment}
            title="Payment Info"
            onClick={() => setStep(CHECKOUT_STEPS.PAYMENT)}
          />
        )}

        <div className="bwt-1 bc-navy-200" />
      </div>

      <div className="flex-none order-summary d-n md:d-b md:w-6/16">
        <style jsx>
          {`
            .order-summary {
              max-width: 531px;
            }
          `}
        </style>
        <OrderSummary
          cart={cart}
          user={user}
          products={products}
        />
      </div>

      <MobileSlideModal
        show={showCartDrawer}
        showHeader={false}
        height="100vh"
        slideDirection="left"
      >
        <div>
          <div className="flex items-center bc-navy-200 bwb-1 px-1">
            <div className="fw-extra-bold fs-lg">Order Summary</div>
            <div className="ml-p75 fw-bold color-navy-700">{`${cartQuantity} ${cartQuantity > 1 ? 'Items' : 'Item'}`}</div>
            <div className="flex-1 flex justify-end" onClick={() => setShowCartDrawer(false)}>
              <MobileHeaderButton
                className="color-navy-700 -mr-p5"
                text="Close"
                icon="window-close"
              />
            </div>
          </div>
          <OrderSummary
            cart={cart}
            user={user}
            products={products}
          />
        </div>
      </MobileSlideModal>
    </div>
  );
}

export default withRouter(Checkout);