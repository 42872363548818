import React from 'react';
import _ from 'lodash';
import { Query } from 'react-apollo';
import {
  AmountsInput,
  Cart,
  Query as OrderQueryResults,
  QueryrequestCartQuoteArgs,
  RequestCompleteQuoteOutput,
  SHIPPING_SPEED_ENUM,
} from 'inkp-order-sdk/types.g';
import { Product } from 'inkp-product-sdk/types.g';
import { User } from 'inkp-user-sdk/types.g';
import { SHIPPING_SPEED_READ_MAP } from 'inkp-order-sdk/shipping';
import Loading from 'inkp-components/dist/Components/Loading';

import CartProductDisplay from '../../../components/CartProductDisplay';
import {
  joinCartProductsWithQuotes,
  QUERY_REQUEST_CART_QUOTE,
  getCouponTotals,
  formatPrice,
  getCartAmounts,
  calculateCartProductQuantity
} from '../../../util/Checkout';
import { CartItemsToCartProducts } from '../../../util/Product';
import { CartProductWithQuotes } from '../../../interfaces/CartCheckout';
import { CartProduct } from '../../../interfaces/CartProduct';

class QueryRequestCartQuote extends Query<OrderQueryResults, QueryrequestCartQuoteArgs> {}

interface Props {
  cart: Cart;
  products: Product[];
  user: User;
}

interface State {}

class OrderSummary extends React.Component<Props, State> {

  renderOrderTotal(
    cart: Cart,
    cartQuotes: RequestCompleteQuoteOutput[],
  ) {
    const totalAmounts: AmountsInput = getCartAmounts(cartQuotes);
    const totalCoupons = getCouponTotals(cartQuotes);

    const shippingSpeed = cart.shippingDetails && cart.shippingDetails.speed ?
      cart.shippingDetails.speed :
      SHIPPING_SPEED_ENUM.NINE_DAY;

    const stateCode = cart.shippingAddress && cart.shippingAddress.state ?
      cart.shippingAddress.state : null;
    
    return (
      <div className="-my-p5">
        <div className="flex justify-between my-p5">
          <span>Subtotal</span>
          <span>{`$${formatPrice(totalAmounts.subtotal - totalAmounts.shipping + totalAmounts.discount)}`}</span>
        </div>
        <div className="flex justify-between my-p5">
          <span>Shipping ({SHIPPING_SPEED_READ_MAP[shippingSpeed]})</span>
          <span>{totalAmounts.shipping > 0 ? `$${formatPrice(totalAmounts.shipping)}` : 'Free'}</span>
        </div>
        <div className="flex justify-between my-p5">
          <span>{stateCode ? `Tax (${stateCode})` : 'Tax'}</span>
          <span>{`$${formatPrice(totalAmounts.tax)}`}</span>
        </div>
        {totalCoupons.map((totalCoupon) => (
          <div className="flex justify-between my-p5">
            <span>{totalCoupon.description || totalCoupon.displayCode || totalCoupon.code}</span>
            <span>-{`$${formatPrice(totalCoupon.discount)}`}</span>
          </div>
        ))}
        <div className="my-1p5 bc-navy-200 bwb-1"></div>
        <div className="flex justify-between items-center">
          <span className="fw-extra-bold" style={{ fontSize: '18px' }}>Total</span>
          <span className="fw-bold color-primary" style={{ fontSize: '18px' }}>{`$${formatPrice(totalAmounts.total)}`}</span>          
        </div>
      </div>
    )
  }

  renderCartProductDisplay(
    cartProductsWithQuotes: CartProductWithQuotes[],
  ) {
    return (
      <div className="-my-1p5">
        {_.map(cartProductsWithQuotes, (cartProductWithQuote) => (
          <div className="my-1p5 lg:flex-no-shrink lg:flex-no-grow lg:flex-basis-auto lg:w-auto">
            <CartProductDisplay 
              cartId={this.props.cart.id}
              cartItems={this.props.cart.items}
              cartProduct={cartProductWithQuote}
              cartProductWithQuotes={cartProductWithQuote}
              error={undefined}
              showEditQty={false}
            />
          </div>
        ))}
      </div>
    )
  }

  render() {  
    const { cart, products } = this.props;
    const cartQuantity = calculateCartProductQuantity(cart);

    return (
      <QueryRequestCartQuote
        query={QUERY_REQUEST_CART_QUOTE}
        variables={{ cartId: cart.id }}
        fetchPolicy="network-only"
      >
        {({ error, loading, data, refetch }) => {
          const cartQuotes = data && data.requestCartQuote;
          if (loading) {
            return <Loading />
          }

          if (error || !cartQuotes) {
            return `Failed to load cart quotes for cart ${cart.id}`;
          }          

          const cartProducts: CartProduct[] = CartItemsToCartProducts(cart.items, products);
          const cartProductsWithQuotes: CartProductWithQuotes[] = joinCartProductsWithQuotes(
            cartProducts,
            cartQuotes,
          );

          return (
            <div className="w-full">
              <div className="d-n md:flex bc-navy-200 bwb-1 justify-between items-center">
                <span className="px-1 lg:px-3 py-1 color-navy fw-extra-bold">Order Summary</span>
                <span className="px-1 lg:px-3 color-navy-700 fw-bold">{`${cartQuantity} ${cartQuantity > 1 ? 'Items' : 'Item'}`}</span>
              </div>
              <div className="mx-1 lg:mx-3">
                <div className="pt-1p5">
                  {this.renderCartProductDisplay(cartProductsWithQuotes)}
                </div>
                <div className="pt-1p5 bc-navy-200 bwb-1"></div>
                <div className="pt-1p5">
                  {this.renderOrderTotal(cart, cartQuotes)}
                </div>
              </div>
            </div>
          )
        }}
      </QueryRequestCartQuote>
    )
  }
}

export default OrderSummary;