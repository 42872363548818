import React from 'react';
import _ from 'lodash';
import { DataProxy } from 'apollo-cache';
import { Query, Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { validateShippingParams } from 'inkp-order-sdk/cart';
import { routes } from 'inkp-routes';

import { User } from 'inkp-user-sdk/types.g';
import {
  AmountsInput,
  Cart,
  CartCouponInput,
  CartItem,
  Mutation as MutationUpdateCartResults,
  MutationupdateCartArgs,
  QUOTE_COUPON_RESPONSE_LEVEL_ENUM,
  Query as QueryOrderResults,
  QueryrequestCartQuoteArgs,
  QueryrequestShippingQuotesArgs,
  QuoteCouponResponse,
  RequestCompleteQuoteOutput,
  ShippingQuote,
  SHIPPING_SPEED_ENUM,
  CartShipping,
  CartShippingInput,
} from 'inkp-order-sdk/types.g';
import { US_STATES_HASH_MAP, COUNTRIES_HASH_MAP } from 'inkp-order-sdk/constants';
import { ADDRESS_LENGTH_LIMITS } from 'oo-print-sdk/shipping';

import Input from 'inkp-components/dist/Components/Input';
import Select from 'inkp-components/dist/Components/Select';
import Button from 'inkp-components/dist/Components/Button';
import Loading from 'inkp-components/dist/Components/Loading';
import Alert from 'inkp-components/dist/Components/Alert';

import ShippingOptions from './ShippingOptions';
import ClosedStep from '../ClosedStep';
import {
  MUTATION_UPDATE_CART,
  QUERY_REQUEST_CART_QUOTE,
  QUERY_REQUEST_SHIPPING_QUOTES,
  updateCurrentCart,
  joinCartProductsWithQuotes,
  getCartProductErrors,
} from '../../../../util/Checkout';

// GTM helpers
import GTM from '../../../../util/gtm';
import { GTMTypes } from '../../../../interfaces/GTM';

const CURRENT_DEFAULT_COUNTRY_CODE: string = 'US';

interface InputValues {
  value: string;
  touched: boolean;
}
interface Props {
  cart: Cart;
  user: User;
  onNext: () => void;
}
interface State {
  [id: string]: any;
  name: InputValues;
  address1: InputValues;
  address2: InputValues;
  city: InputValues;
  state: InputValues;
  zip: InputValues;
  country: InputValues;
  showAddress2: boolean;
  shippingDetails?: CartShipping;
}

class MutationUpdateCart extends Mutation<MutationUpdateCartResults, MutationupdateCartArgs> {}

export default class Shipping extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { shippingAddress, shippingDetails } = props.cart;
    let name: string = props.user.name || '';
    let address1: string = '';
    let address2: string = '';
    let city: string = '';
    let state: string = '';
    let zip: string = '';
    let country: string = CURRENT_DEFAULT_COUNTRY_CODE;

    if (shippingAddress) {
      name = shippingAddress.name || name;
      address1 = shippingAddress.address1 || address1;
      address2 = shippingAddress.address2 || address2;
      city = shippingAddress.city || city;
      state = shippingAddress.state || state;
      zip = shippingAddress.zip || zip;
      country = shippingAddress.country || CURRENT_DEFAULT_COUNTRY_CODE;
    }

    name = name && name.slice(0, ADDRESS_LENGTH_LIMITS.name);
    address1 = address1 && address1.slice(0, ADDRESS_LENGTH_LIMITS.address1);
    address2 = address2 && address2.slice(0, ADDRESS_LENGTH_LIMITS.address2);
    city = city && city.slice(0, ADDRESS_LENGTH_LIMITS.city);
    state = state && state.slice(0, ADDRESS_LENGTH_LIMITS.state);
    zip = zip && zip.slice(0, ADDRESS_LENGTH_LIMITS.zip);

    this.state = {
      name: { value: name, touched: !!name },
      address1: { value: address1, touched: !!address1 },
      address2: { value: address2, touched: !!address2 },
      city: { value: city, touched: !!city },
      state: { value: state, touched: !!state },
      zip: { value: zip, touched: !!zip },
      country: { value: country, touched: !!country },
      showAddress2: !!address2,
      shippingDetails,
    };
  }

  showAddress2 = () => (event: React.MouseEvent<HTMLDivElement>) => {
    return this.setState({
      showAddress2: true,
    });
  };

  renderErrorsForField(validationErrors: any) {
    if (_.isEmpty(validationErrors)) return null;

    return validationErrors.map(({ message }: { message: string }, index: number) => (
      <div key={index}>
        {message && (
          <div className="mt-p5 ml-1 fs-xs fw-bold color-red">
            <i className="mdi mdi-alert fs-sm-icon color-red pr-p25" />
            {message}
          </div>
        )}
      </div>
    ));
  }

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    const { cart } = this.props;
    const { name, address1, address2, zip, state, country, city, showAddress2, shippingDetails } = this.state;
    const fieldsToValidate = {
      name: name.value,
      address1: address1.value,
      address2: address2.value,
      zip: zip.value,
      state: state.value,
      country: country.value,
      city: city.value,
    };

    const assignedShippingAddress = Object.assign({}, cart.shippingAddress, {
      name: name.value,
      address1: address1.value,
      address2: address2.value,
      zip: zip.value,
      state: state.value,
      country: country.value,
      city: city.value,
    });
    delete assignedShippingAddress.__typename;

    return (
      <MutationUpdateCart
        mutation={MUTATION_UPDATE_CART}
        update={(cache: DataProxy, { data }: { data: MutationUpdateCartResults }) => {
          updateCurrentCart(cache, data.updateCart);
          this.props.onNext();
        }}
        refetchQueries={[{ query: QUERY_REQUEST_CART_QUOTE, variables: { cartId: cart.id } }]}
      >
        {(updateCart, { error: updateCartError, loading: updateCartLoading, data: updateCartData }) => {
          // tslint:disable-next-line
          const validationErrorsArray = validateShippingParams(fieldsToValidate);
          const validationErrors = _.groupBy(validationErrorsArray, 'field');

          return (
            <div className="mx-1 md:mx-0">
              <div className="color-primary fw-extra-bold">Step 2</div>
              <div className="fs-lg md:fs-xl fw-extra-bold mt-p25">Shipping Info</div>
              {updateCartError && <div>ERROR UPDATING</div>}
              <Input
                className="mt-1 md:mt-1p5"
                label="Full Name"
                value={name.value}
                onChange={(e) => {
                  const name = e.target.value || '';
                  const nameLimit = name.slice(0, ADDRESS_LENGTH_LIMITS.name);
                  this.setState({
                    name: {
                      value: nameLimit,
                      touched: true,
                    },
                  });
                }}
                error={(updateCartError || name.touched) && !!validationErrors.name}
                helper={(updateCartError || name.touched) && this.renderErrorsForField(validationErrors.name)}
              />
              <Input
                className="mt-1p5"
                label="Street Address"
                value={address1.value}
                onChange={(e) => {
                  const address1 = e.target.value.slice(0, ADDRESS_LENGTH_LIMITS.address1);
                  this.setState({
                    address1: {
                      value: address1,
                      touched: true,
                    },
                  });
                }}
                error={(updateCartError || address1.touched) && !!validationErrors.address1}
                helper={(updateCartError || address1.touched) && this.renderErrorsForField(validationErrors.address1)}
              />
              {showAddress2 ? (
                <Input
                  className="mt-1p5"
                  label="Apt, Suite, Building, etc"
                  value={address2.value}
                  onChange={(e) => {
                    const address2 = e.target.value.slice(0, ADDRESS_LENGTH_LIMITS.address2);
                    this.setState({
                      address2: {
                        value: address2,
                        touched: true,
                      },
                    });
                  }}
                />
              ) : (
                <div
                  className="flex items-center mt-1 fs-md fw-bold color-navy-700 cursor-pointer"
                  onClick={this.showAddress2()}
                >
                  <span className="d-b w-1p5 h-1p5 mr-p5">
                    <i className="mdi mdi-24px mdi-plus-circle-outline lg-icon"></i>
                  </span>
                  <span className="d-b">Add apt, suite, company, c/o (optional)</span>
                </div>
              )}

              <div className="flex -mx-p5 mt-1p5">
                <div className="w-1/2 px-p5">
                  <Input
                    label="City"
                    value={city.value}
                    onChange={(e) => {
                      const city = e.target.value;
                      const cityLimit = city.slice(0, ADDRESS_LENGTH_LIMITS.city);
                      this.setState({
                        city: {
                          value: cityLimit,
                          touched: true,
                        },
                      });
                    }}
                    error={(updateCartError || city.touched) && !!validationErrors.city}
                    helper={(updateCartError || city.touched) && this.renderErrorsForField(validationErrors.city)}
                  />
                </div>
                <div className="w-1/2 px-p5">
                  <Select
                    placeholder="State"
                    label="State"
                    labelColor="color-gray-700"
                    selected={state.value || null}
                    options={_.toPairs(US_STATES_HASH_MAP).map(([value, display]) => ({ value, display }))}
                    onChange={(e) => {
                      const state = e.target.value;
                      const stateLimit = state.slice(0, ADDRESS_LENGTH_LIMITS.state);
                      this.setState({
                        state: {
                          value: stateLimit,
                          touched: true,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {(updateCartError || state.touched) && this.renderErrorsForField(validationErrors.state)}
              <div className="flex -mx-p5 mt-1p5">
                <div className="w-1/2 px-p5">
                  <Input
                    className="w-full"
                    label="Zip Code"
                    value={zip.value}
                    onChange={(e) => {
                      const zip = e.target.value;
                      const zipLimit = zip.slice(0, ADDRESS_LENGTH_LIMITS.zip);
                      this.setState({
                        zip: {
                          value: zipLimit,
                          touched: true,
                        },
                      });
                    }}
                    error={(updateCartError || zip.touched) && !!validationErrors.zip}
                    helper={(updateCartError || zip.touched) && this.renderErrorsForField(validationErrors.zip)}
                  />
                </div>
                <div className="w-1/2 px-p5">
                  <Input label="Country" value={COUNTRIES_HASH_MAP.US} className="w-full" disabled={true} />
                </div>
              </div>

              <div className="mt-2">
                <ShippingOptions
                  cart={cart}
                  address={assignedShippingAddress}
                  activeShippingSpeed={_.get(shippingDetails, 'speed')}
                  onSelectShipping={(speed) => {
                    const shippingDetails = { speed };
                    this.setState({ shippingDetails });
                    updateCart({
                      variables: {
                        id: cart.id,
                        data: {
                          shippingDetails,
                        },
                      },
                      update: (cache: DataProxy, { data }: { data: MutationUpdateCartResults }) => {
                        updateCurrentCart(cache, data.updateCart);
                      },
                    });
                  }}
                />
              </div>

              <div className="flex flex-row-reverse mt-1p5">
                <div className="next-step-button flex-none">
                  <Button
                    size="xl"
                    corners="full"
                    block={true}
                    disabled={validationErrorsArray.length > 0 || !_.get(shippingDetails, 'speed') || updateCartLoading}
                    loading={updateCartLoading}
                    onClick={() => {
                      return updateCart({
                        variables: {
                          id: cart.id,
                          data: {
                            shippingAddress: assignedShippingAddress,
                            billingAddress: assignedShippingAddress,
                            shippingDetails: shippingDetails as CartShippingInput,
                          },
                        },
                      });
                    }}
                  >
                    <span style={{ fontSize: '18px', lineHeight: '28px' }}>Continue to Payment</span>
                  </Button>
                </div>
              </div>
            </div>
          );
        }}
      </MutationUpdateCart>
    );
  }
}
