import * as React from 'react';
import classnames from 'classnames';
import { CartFeatures } from 'inkp-order-sdk/types.g';
import Popover from 'inkp-components/dist/Components/Popover';
import usePopoverAnchor from 'inkp-components/dist/hooks/usePopoverAnchor';
import OptionCard from 'inkp-components/dist/Components/OptionCard';

interface Props {
  /**
   * Features from the Cart
   * @type {CartFeatures | undefined}
   * @required
   */
  features: CartFeatures | undefined;

  /**
   * Accept artwork approval handler
   * @type {function}
   * @required
   */
  onApprovalAccepted: () => void;

  /**
   * Reject artwork approval handler
   * @type {function}
   * @required
   */
  onApprovalRejected: () => void;
}

const ArtworkApproval: React.FunctionComponent<Props> = ({ features, onApprovalAccepted, onApprovalRejected }) => {
  const requiresCustomerApproval: boolean | undefined =
    features && (features.requiresCustomerApproval !== undefined && features.requiresCustomerApproval !== null)
      ? features.requiresCustomerApproval
      : undefined;
  const approved: boolean = !!requiresCustomerApproval;
  const rejected: boolean =
    requiresCustomerApproval !== undefined ? !requiresCustomerApproval : !!requiresCustomerApproval;
  const tooltipRef = React.useRef<HTMLSpanElement>(null);
  const anchorData = usePopoverAnchor(tooltipRef);
  const [tooltipHover, setTooltipHover] = React.useState(false);

  return (
    <React.Fragment>
      <div className="flex items-center">
        <div className="fw-extra-bold capitalize">artwork approval</div>
        <span
          ref={tooltipRef}
          className="mx-p25 color-navy-700 color-navy:hover"
          onMouseEnter={() => { setTooltipHover(true) }}
          onMouseLeave={() => { setTooltipHover(false) }}
        >
          <i className="mdi mdi-information-outline fs-icon-1p5" />
        </span>
        { anchorData && tooltipHover &&
          <Popover
            anchorData={anchorData}
            position="top"
            style="tooltip"
            hoverable={true}
            onMouseEnter={() => {}}
            onMouseLeave={() => {}}
          >
            <div className="fs-xs w-12 xl:w-auto">A digital proof will be sent for your approval before we start printing</div>
          </Popover>
        }
      </div>
      <p className="mb-t5">Do you want to approve the artwork before we start printing?</p>

      <OptionCard
        className="mt-1 md:mt-1p5 cursor-pointer"
        borderless={true}
        active={approved}
        onSelect={() => onApprovalAccepted()}
      >
        <div className="ml-1">
          <div className={`fw-bold color-${approved ? 'primary' : 'navy-700'}`}>Yes, send me an approval email for me to review.</div>
          <div className={`${approved ? '' : 'color-navy-700'}`}>
            Printing won’t start without your approval, this may delay the delivery (1-2 days).
          </div>
        </div>
      </OptionCard>

      <OptionCard
        className="mt-1 md:mt-1p5 cursor-pointer"
        borderless={true}
        active={rejected}
        onSelect={() => onApprovalRejected()}
      >
        <div className={`ml-1 fw-bold color-${rejected ? 'primary' : 'navy-700'}`}>
          No, start printing right away.
        </div>
      </OptionCard>
    </React.Fragment>
  );
};

export default ArtworkApproval;
