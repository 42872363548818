import * as React from 'react';
import * as _ from 'lodash';
import Input from 'inkp-components/dist/Components/Input';
import { BraintreeError } from 'braintree-web';
import { HostedFieldsProvider, HostedCardNumber, HostedCVV, HostedExpiry, HostedForm } from 'sp-hosted-fields';

enum CC_BRAND_ENUM {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  MAESTRO = 'maestro',
  DISCOVER = 'discover',
  AMEX = 'amex',
}

const VALIDATION_MESSAGES: { [id: string]: string } = {
  isCardNumberValid: 'Please provide a valid card number.',
  isCVVValid: 'Please provide a valid CVV.',
  isExpirationDateValid: 'Please provide a valid expiration date.',
  name: 'Please provide your full name.',
};

const ErrorMessage = (message: string) => (
  <div className="mt-p5 ml-1 fs-xs fw-bold color-red">
    <i className="mdi mdi-close fs-md fs-sm-icon color-red pr-p25" />
    {message}
  </div>
);
const WarningMessage = (message: string) => (
  <div className="mt-p5 ml-1 fs-xs fw-bold color-gray-400">
    <i className="mdi mdi-alert fs-md fs-sm-icon color-gray-400 pr-p25" />
    {message}
  </div>
);

interface Props {
  hostedFieldsError: BraintreeError | null;
  onValid: (event: boolean) => void;
}
interface State {
  [id: string]: any;
  name: {
    value: string;
    touched: boolean;
  };
  isCardNumberValid: boolean;
  isCVVValid: boolean;
  isExpirationDateValid: boolean;
  previoushostedFieldsError: string;
  brand: string;
}

const renderIdleCreditCards = () => {
  return (
    <React.Fragment>
      <img
        className="ml-p25"
        width="34"
        height="24"
        src="https://inkp-production.32pt.com/public/assets/visa-idle.svg"
        alt="Credit card VISA idle icon"
      />
      <img
        className="ml-p25"
        width="34"
        height="24"
        src="https://inkp-production.32pt.com/public/assets/master-idle.svg"
        alt="Credit card Mastercard idle icon"
      />
      <img
        className="ml-p25"
        width="34"
        height="24"
        src="https://inkp-production.32pt.com/public/assets/discover-idle.svg"
        alt="Credit card Discover idle icon"
      />
      <img
        className="ml-p25"
        width="34"
        height="24"
        src="https://inkp-production.32pt.com/public/assets/amex-idle.svg"
        alt="Credit Card American Express idle icon"
      />
    </React.Fragment>
  );
};

const renderActiveCard = (type: CC_BRAND_ENUM) => {
  let src = '';
  let alt = '';
  switch (type) {
    case CC_BRAND_ENUM.VISA: {
      src = 'https://inkp-production.32pt.com/public/assets/visa-active.svg';
      alt = 'Credit Card VISA active icon';
      break;
    }
    case CC_BRAND_ENUM.MASTERCARD:
    case CC_BRAND_ENUM.MAESTRO: {
      src = 'https://inkp-production.32pt.com/public/assets/master-active.svg';
      alt = 'Credit card Mastercard active icon';
      break;
    }
    case CC_BRAND_ENUM.DISCOVER: {
      src = 'https://inkp-production.32pt.com/public/assets/discover-active.svg';
      alt = 'Credit card Discover active icon';
      break;
    }
    case CC_BRAND_ENUM.AMEX: {
      src = 'https://inkp-production.32pt.com/public/assets/amex-active.svg';
      alt = 'Credit card American Express active icon';
      break;
    }
    default: {
      return null;
    }
  }
  return <img className="ml-p25" width="34" height="24" src={src} alt={alt} />;
};

const renderCreditCardIcons = (brand: string) => {
  switch (brand) {
    case CC_BRAND_ENUM.AMEX:
    case CC_BRAND_ENUM.DISCOVER:
    case CC_BRAND_ENUM.MAESTRO:
    case CC_BRAND_ENUM.MASTERCARD:
    case CC_BRAND_ENUM.VISA: {
      return renderActiveCard(brand);
    }
    case '': {
      return renderIdleCreditCards();
    }
    default: {
      return null;
    }
  }
};

class PaymentForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { hostedFieldsError } = this.props;
    let isCardNumberValid: boolean = true;
    let isCVVValid: boolean = true;
    let isExpirationDateValid: boolean = true;
    if (hostedFieldsError) {
      const { details } = hostedFieldsError;
      if (!details) {
        isCardNumberValid = false;
        isCVVValid = false;
        isExpirationDateValid = false;
      }
    }

    this.state = {
      name: {
        value: '',
        touched: false,
      },
      brand: '',
      isCardNumberValid,
      isCVVValid,
      isExpirationDateValid,
      previoushostedFieldsError: JSON.stringify(hostedFieldsError),
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { hostedFieldsError } = props;
    const { previoushostedFieldsError } = state;
    const sanitizedHostedFieldsError: any = Object.assign(
      {},
      {
        ...hostedFieldsError,
      }
    );
    if (
      sanitizedHostedFieldsError &&
      sanitizedHostedFieldsError.details &&
      sanitizedHostedFieldsError.details.invalidFields
    ) {
      delete sanitizedHostedFieldsError.details.invalidFields;
    }
    if (hostedFieldsError && previoushostedFieldsError !== JSON.stringify(sanitizedHostedFieldsError)) {
      const { details } = hostedFieldsError;
      const { name } = state;
      if (!details) {
        return {
          isCardNumberValid: false,
          isCVVValid: false,
          isExpirationDateValid: false,
          name: {
            ...name,
            touched: true,
          },
          previoushostedFieldsError: JSON.stringify(sanitizedHostedFieldsError),
        };
      }
      const { invalidFieldKeys } = details;
      if (invalidFieldKeys && invalidFieldKeys.length > 0) {
        return {
          isCardNumberValid: invalidFieldKeys.indexOf('number') === -1,
          isCVVValid: invalidFieldKeys.indexOf('cvv') === -1,
          isExpirationDateValid: invalidFieldKeys.indexOf('expirationDate') === -1,
          name: {
            ...name,
            touched: true,
          },
          previoushostedFieldsError: JSON.stringify(sanitizedHostedFieldsError),
        };
      }
    }
    return null;
  }

  isNameValid = (): boolean => {
    return !!this.state.name.value;
  };

  handleValidForm = (isFormValid: boolean) => {
    // const { isCardNumberValid, isCVVValid, isExpirationDateValid } = this.state;
    // const isFormValid: boolean = this.isNameValid() && isCardNumberValid && isCVVValid && isExpirationDateValid;
    if (this.props.onValid) this.props.onValid(isFormValid);
  };

  onChangeCardNumber = () => (event: { brand: string }) => {
    return this.setState({
      brand: event.brand,
    });
  };

  onValidCardNumber = () => () => {
    console.log('[DEBUG] onValidCardNumber');
    const { isCVVValid, isExpirationDateValid } = this.state;
    const isCardNumberValid: boolean = true;
    const isFormValid: boolean = this.isNameValid() && isCardNumberValid && isCVVValid && isExpirationDateValid;
    this.handleValidForm(isFormValid);
    this.setState({
      isCardNumberValid,
    });
  };

  onInvalidCardNumber = () => () => {
    console.log('[DEBUG] onInvalidCardNumber');
    const isCardNumberValid: boolean = false;
    this.handleValidForm(isCardNumberValid);
    this.setState({
      isCardNumberValid,
    });
  };

  onEmptyCardNumber = () => () => {
    setTimeout(() => {
      this.setState({
        brand: '',
      });
    }, 0);
  };

  onValidCVV = () => () => {
    console.log('[DEBUG] onValidCVV');
    const { isCardNumberValid, isExpirationDateValid } = this.state;
    const isCVVValid: boolean = true;
    const isFormValid: boolean = this.isNameValid() && isCardNumberValid && isCVVValid && isExpirationDateValid;
    this.handleValidForm(isFormValid);
    this.setState({
      isCVVValid,
    });
  };

  onInvalidCVV = () => () => {
    console.log('[DEBUG] onInvalidCVV');
    const isCVVValid: boolean = false;
    this.handleValidForm(isCVVValid);
    this.setState({
      isCVVValid,
    });
  };

  onValidExpirationDate = () => () => {
    console.log('[DEBUG] onValidExpirationDate');
    const { isCardNumberValid, isCVVValid } = this.state;
    const isExpirationDateValid: boolean = true;
    const isFormValid: boolean = this.isNameValid() && isCardNumberValid && isCVVValid && isExpirationDateValid;
    this.handleValidForm(isFormValid);
    this.setState({
      isExpirationDateValid,
    });
  };

  onInvalidExpirationDate = () => () => {
    console.log('[DEBUG] onInvalidExpirationDate');
    const isExpirationDateValid: boolean = false;
    this.handleValidForm(isExpirationDateValid);
    this.setState({
      isExpirationDateValid,
    });
  };

  onChangeName = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = event.target.value;
    const { isCardNumberValid, isCVVValid, isExpirationDateValid } = this.state;
    const isFormValid: boolean = !!name && isCardNumberValid && isCVVValid && isExpirationDateValid;
    this.handleValidForm(isFormValid);
    this.setState({
      name: {
        value: name,
        touched: true,
      },
    });
  };

  renderErrorsForField(hostedFieldsError: BraintreeError | null, field: string) {
    const validationMessage: string = VALIDATION_MESSAGES[field];
    const messageType: any = hostedFieldsError ? ErrorMessage : WarningMessage;
    const isFieldValid: boolean = this.state[field];
    if (field !== 'name') {
      if (!isFieldValid && !!hostedFieldsError) {
        return messageType(validationMessage);
      }
      return null;
    }
    if (!this.isNameValid()) {
      return messageType(validationMessage);
    }
    return null;
  }

  render() {
    const { name, brand } = this.state;
    const { hostedFieldsError } = this.props;
    console.log('[DEBUG] hostedFieldsError', hostedFieldsError);
    return (
      <div className="w-full flex justify-center">
        <HostedForm className="w-full">
          <div>
            <div className="p-relative">
              <div className="p-absolute pin-r w-1/2 h-full flex items-center justify-end mr-p75">
                {renderCreditCardIcons(brand)}
              </div>
              <Input
                label={'Card Number'}
                className="w-full"
                inputComponent={HostedCardNumber}
                onValid={this.onValidCardNumber()}
                onInvalid={this.onInvalidCardNumber()}
                error={!this.state.isCardNumberValid}
                value={''}
                onChange={this.onChangeCardNumber()}
                onEmpty={this.onEmptyCardNumber()}
              />
            </div>
            {this.renderErrorsForField(hostedFieldsError, 'isCardNumberValid')}
          </div>
          <div className="flex mt-1p5 -mx-p5">
            <div className="w-1/2 px-p5">
              <Input
                label={'Exp. Date MM/YY'}
                className="w-full"
                inputComponent={HostedExpiry}
                onValid={this.onValidExpirationDate()}
                onInvalid={this.onInvalidExpirationDate()}
                error={!this.state.isExpirationDateValid}
                onChange={(event: any) => {}}
              />
              {this.renderErrorsForField(hostedFieldsError, 'isExpirationDateValid')}
            </div>
            <div className="w-1/2 px-p5">
              <Input
                label={'CVV'}
                className="w-full"
                inputComponent={HostedCVV}
                onValid={this.onValidCVV()}
                onInvalid={this.onInvalidCVV()}
                error={!this.state.isCVVValid}
                onChange={(event: any) => {}}
              />
              {this.renderErrorsForField(hostedFieldsError, 'isCVVValid')}
            </div>
          </div>
          <div className="mt-1p5">
            <Input
              label={'Cardholder’s Name'}
              className="w-full"
              onChange={this.onChangeName()}
              value={name.value}
              error={name.touched && !name.value}
            />
            {this.state.name.touched && this.renderErrorsForField(hostedFieldsError, 'name')}
          </div>
        </HostedForm>
      </div>
    );
  }
}

export default PaymentForm;
