import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DataProxy } from 'apollo-cache';
import { MutationFn } from 'react-apollo';
import { path, routes } from 'inkp-routes';

import {
  CartItem,
  CartItemInput,
  CartItemProductInput,
  Mutation as MutationOrderResults,
  MutationupdateCartArgs,
  RequestCompleteQuoteOutput,
} from 'inkp-order-sdk/types.g';
import { Product, ProductColor, ProductSize } from 'inkp-product-sdk/types.g';
import { stringifyProductItemId, prettyProductSize, PRODUCT_CATEGORY_SCOPES, parseProductItemId } from 'inkp-product-sdk';
import { MINIMUM_DTG_QUANTITY, MAX_PRODUCT_QUANTITY, getCartProductTotalQuantity } from 'inkp-order-sdk/order';
import { CartProductWithQuotes } from '../../../../interfaces/CartCheckout';
import {
  CART_PRODUCT_ERRORS,
  MUTATION_UPDATE_CART,
  MutationUpdateCart,
  QUERY_REQUEST_CART_QUOTE,
  updateCurrentCart,
  getSizeInStockInfo,
  STRAIGHTEN_SVG,
} from '../../../../util/Checkout';
import {
  MAX_PRODUCT_INPUT,
  SIDES_BY_ORDER,
} from '../../../../util/Product';
import Image from 'inkp-components/dist/Components/Image';
import Button from 'inkp-components/dist/Components/Button';
import PriceDisplayer from 'inkp-components/dist/Components/PriceDisplayer';
import Alert from 'inkp-components/dist/Components/Alert';
import Loading from 'inkp-components/dist/Components/Loading';
import Popover from 'inkp-components/dist/Components/Popover';
import { computeCoordinates } from 'inkp-components/dist/hooks/usePopoverAnchor';
import TextArea from 'inkp-components/dist/Components/TextArea';
import { Design } from 'inkp-design-sdk/types.g';


interface Props extends RouteComponentProps {
  cartId: string;
  cartItems: CartItem[];
  cartProductWithQuotes: CartProductWithQuotes;
  error?: { code: CART_PRODUCT_ERRORS, data?: any };
  onSizeGuideClick: (product: Product, brand: string, sizes: string[], prettyBrand: string) => () => void;
  onUpdateDesignNote: (args: any) => void;
}

type CartItemTab = 'size' | 'note' | null;
interface State {
  cartItemTab: CartItemTab;
  showSizeGuide: boolean;
  sizesQuantities: { [id: string]: number };
  hoverImageIndex: number;
}

class CartProductItem extends React.Component<Props, State> {
  readonly state: State;
  imageRefs: React.RefObject<HTMLImageElement>[];

  constructor(props: Props) {
    super(props);
    const { cartProductWithQuotes } = props;
    this.imageRefs = _.map(_.range(_.size(cartProductWithQuotes.mockups)), () => {
      return React.createRef<HTMLImageElement>();
    });

    this.state = {
      cartItemTab: null,
      showSizeGuide: false,
      sizesQuantities: {
        ...cartProductWithQuotes.sizesQuantities,
      },
      hoverImageIndex: -1,
    };
  }

  private setHoverImageIndex(index: number) {
    this.setState(() => {
      return { hoverImageIndex: index };
    });
  }

  private changeSize(size: string, qty: number) {
    const { sizesQuantities } = this.state;
    this.setState({
      sizesQuantities: {
        ...sizesQuantities,
        [size]: qty,
      },
    });
  }

  private onSizeQuantityChange = (size: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = ev.currentTarget.value;
    const newQty: number = newValue === '' ? 0 : parseInt(ev.target.value, 10);
    if (isNaN(newQty) || newQty < 0 || newQty > MAX_PRODUCT_INPUT) {
      return;
    }

    this.changeSize(size, newQty);
  };

  private changeProductTab = (tab: CartItemTab) => () => {
    this.setState({ cartItemTab: tab });
  }

  private onEditSizesClick = (showSizes: boolean) => () => {
    if (showSizes) {
      const { sizesOutOfStock } = getSizeInStockInfo(this.props.cartProductWithQuotes, this.state.sizesQuantities);
      sizesOutOfStock.forEach((size) => this.changeSize(size, 0));
    }

    return this.setState({
      cartItemTab: 'size',
    });
  };

  private onEditDesignClick = () => () => {
    const {
      history,
      cartProductWithQuotes: { cartItemsIndexes },
    } = this.props;
    return history.push(path(routes.app.checkout.editCartDesign, { itemIndexes: cartItemsIndexes.join(',') }));
  };

  private onCancelClick = () => () => {
    const {
      cartProductWithQuotes: { sizesQuantities },
    } = this.props;
    return this.setState({
      cartItemTab: null,
      sizesQuantities: {
        ...sizesQuantities,
      },
    });
  };

  private getRemainingCartItems = (): CartItemInput[] => {
    const {
      cartItems: originalCartItems,
      cartProductWithQuotes: { cartItemsIndexes },
    } = this.props;
    let cartItems: CartItemInput[] = originalCartItems.slice();
    cartItems.splice(cartItemsIndexes[0], cartItemsIndexes.length);
    cartItems = cartItems.map(
      (cartItem: CartItem): CartItemInput => {
        const {
          designId,
          product: { productItemId, quantity, mockupUrl, mockups },
        } = cartItem;
        return {
          designId,
          product: {
            productItemId,
            quantity,
            mockupUrl,
            mockups,
          },
        };
      }
    );
    return cartItems;
  };

  private onRemoveItemClick = (updateCart: MutationFn<MutationOrderResults, MutationupdateCartArgs>) => (
    ev: React.MouseEvent<HTMLButtonElement>
  ) => {
    const newCartItems: CartItemInput[] = this.getRemainingCartItems();
    const { cartId } = this.props;
    return updateCart({
      variables: {
        id: cartId,
        data: {
          items: newCartItems,
        },
      },
      update: (cache: DataProxy, { data: { updateCart } }: any) => {
        updateCurrentCart(cache, updateCart);
      },
      refetchQueries: (mutationResult) => {
        return [
          {
            query: QUERY_REQUEST_CART_QUOTE,
            variables: { cartId },
          },
        ];
      },
      awaitRefetchQueries: true,
    });
  };

  private getUpdatedCartItems = (): CartItemInput[] => {
    const {
      cartItems: originalCartItems,
      cartProductWithQuotes: { id, mockupUrl, mockups, cartItemsIndexes },
    } = this.props;
    const { productId, designId, colorId } = JSON.parse(id);
    const { sizesQuantities } = this.state;
    const updatedCartItemsForCartProduct: CartItemInput[] = Object.keys(sizesQuantities)
      .map((size: string, index: number) => {
        const productItemId: string = stringifyProductItemId(productId, colorId, size);
        const quantity: number = sizesQuantities[size];
        const cartItemProductInput: CartItemProductInput = {
          productItemId,
          quantity,
          mockupUrl,
          mockups,
        };
        return {
          designId,
          product: cartItemProductInput,
        };
      })
      .filter((updatedCartItemForCartProduct: CartItemInput) => updatedCartItemForCartProduct.product.quantity > 0);
    const cartItems: CartItemInput[] = originalCartItems.map((cartItem: CartItem) => {
      const {
        designId,
        product: { productItemId, quantity, mockupUrl, mockups },
      } = cartItem;
      return {
        designId,
        product: {
          productItemId,
          quantity,
          mockupUrl,
          mockups,
        },
      };
    });
    const startIndex: number = cartItemsIndexes[0];
    const cartItemsForCartProductCount: number = cartItemsIndexes.length;
    cartItems.splice(startIndex, cartItemsForCartProductCount, ...updatedCartItemsForCartProduct);
    return cartItems;
  };

  private onUpdateCart = (updateCart: MutationFn<MutationOrderResults, MutationupdateCartArgs>) => (
    ev: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { cartId } = this.props;
    const newCartItems: CartItemInput[] = this.getUpdatedCartItems();
    return updateCart({
      variables: {
        id: cartId,
        data: {
          items: newCartItems,
        },
      },
      update: (cache: DataProxy, { data: { updateCart } }: any) => {
        updateCurrentCart(cache, updateCart);
        this.setState({
          cartItemTab: null,
        });
      },
      refetchQueries: (mutationResult) => {
        return [
          {
            query: QUERY_REQUEST_CART_QUOTE,
            variables: { cartId },
          },
        ];
      },
      awaitRefetchQueries: true,
    });
  };

  renderOutOfStockError(updateCart: MutationFn<MutationOrderResults, MutationupdateCartArgs>) {
    const { cartProductWithQuotes, error } = this.props;
    if (!error) return null;

    const { sizesQuantities } = this.state;

    const product: Product = cartProductWithQuotes.product;
    const productName: string = product.name;

    const { sizesInStock, sizesOutOfStock } = getSizeInStockInfo(cartProductWithQuotes, sizesQuantities);

    const isAllSizeOOS = _.isEmpty(sizesInStock);

    return (
      <Alert
        className="shadow-none"
        alerts={[
          {
            type: 'error',
            title: 'Out of Stock',
            content: `'${productName}' size ${sizesOutOfStock.map((size: string) => prettyProductSize(size)).join(', ')}
              is currently out of stock. Please update sizes or select a different product.`,
            primaryAction: `${isAllSizeOOS ? 'Delete Items' : 'Edit Sizes'}`,
            onPrimaryAction: isAllSizeOOS ? this.onRemoveItemClick(updateCart) : this.onEditSizesClick(true),
          },
        ]}
        attached="bottom"
      />
    );
  }

  renderErrorMessage(updateCart: MutationFn<MutationOrderResults, MutationupdateCartArgs>) {
    const { error } = this.props;
    console.log('[DEBUG] error', error);

    const { cartItemTab } = this.state;
    const showSizes = cartItemTab === 'size';

    if (!error || showSizes) return null;

    return (
      <React.Fragment>
        {error.code === CART_PRODUCT_ERRORS.ITEM_OUT_OF_STOCK && this.renderOutOfStockError(updateCart)}

        {error.code === CART_PRODUCT_ERRORS.MINIMUM_QUANTITY_REQUIRED && (
          <Alert
            className="shadow-none bc-navy-200 bwt-1"
            alerts={[
              {
                type: 'info',
                title: 'Minimum Quantity Required',
                content: `Your product has an order minimum of ${MINIMUM_DTG_QUANTITY}.
                  Please increase your total quantity to ${MINIMUM_DTG_QUANTITY} or more.`,
                primaryAction: 'Edit Sizes',
                onPrimaryAction: this.onEditSizesClick(true),
              },
            ]}
            attached="bottom"
          />
        )}

        {error.code === CART_PRODUCT_ERRORS.MAXIMUM_QUANTITY_EXCEEDED && (
          <Alert
            className="shadow-none bc-navy-200 bwt-1"
            alerts={[
              {
                type: 'info',
                title: 'Maximum Quantity Exceeded',
                content: `Your product quantity exceeds the maximum quantity of ${MAX_PRODUCT_QUANTITY} units.
                  Please contact our customer service for large orders.`,
                primaryAction: 'Edit Sizes',
                onPrimaryAction: this.onEditSizesClick(true),
              },
            ]}
            attached="bottom"
          />
        )}
      </React.Fragment>
    );
  }

  renderSizeQuantitySelector(props: {
    id: string;
    product: Product;
    productBrand: string;
    availableSizes: ProductSize[];
    sizesQuantities: any;
    loading: boolean;
    disabled: boolean;
    updateCart: MutationFn<MutationOrderResults, MutationupdateCartArgs>;
  }) {
    const { id, product, productBrand, availableSizes, sizesQuantities, loading, disabled, updateCart } = props;
    const { onSizeGuideClick } = this.props;
    return (
      <div className="bgc-gray-50 brb">
        <div className="px-p75 pt-p75 lg:px-1p5 pt-1p5 fs-sm flex justify-between bwt-1 bc-navy-200 color-navy-800 fw-bold">
          <div className="fs-md">Sizes</div>
          <button
            className="flex items-center"
            onClick={onSizeGuideClick(product, productBrand, availableSizes.map((size) => { return size.name; }), product.brand)}
          >
            <img src={STRAIGHTEN_SVG} className="color-navy-800 fs-icon-p5" />
            <span className="px-p25 fs-xs fw-bold color-navy-800">Size Guide</span>
          </button>
        </div>
        <div className="sizes-container w-full px-p5 pt-p5 pb-1 lg:px-1p5 lg:pb-1p5 lg:-px-p5 flex flex-wrap content-start">
          {availableSizes.map((availableSize: ProductSize, availableSizeIndex: number) => {
            const value: string = sizesQuantities[availableSize.name]
              ? `${sizesQuantities[availableSize.name]}`
              : '';
            const inStock = availableSize.inStock;
            return (
              <div
                key={`sizesgroup-${id}-${availableSizeIndex}`}
                className={classnames("lg:w-1/5 lg:px-p25 py-p25 ta-center", {"color-gray-500": !inStock})}
              >
                <label className="d-b mb-p25 fs-xs uppercase fw-bold" htmlFor="">
                  {prettyProductSize(availableSize.name)}
                </label>
                <input
                  className={classnames("d-b w-3p5 lg:w-4p5 h-2p5 mx-2px lg:mx-auto lg:px-p75 py-p5 bgc-white bc-navy-300 bw-1 br ta-center color-navy", {"bc-gray-300": inStock}, {"bc-gray-100": !inStock})}
                  type="number"
                  min="0"
                  max={MAX_PRODUCT_INPUT}
                  step="1"
                  value={value}
                  disabled={!inStock}
                  onChange={this.onSizeQuantityChange(availableSize.name)}
                />
                {!inStock &&
                  <label className="label d-b mb-p25 fs-xs-n fw-bold px-p25 py-p25 break-words" htmlFor="">
                    Out of stock
                  </label>
                }
              </div>
            );
          })}
        </div>
        <div
          className='actions-container flex justify-end items-center bgc-white bc-navy-200 bwt-1 brb'
        >
          <Button
            type="text"
            block={true}
            color="secondary"
            className="fw-bold color-gray-700 py-p5"
            loading={loading}
            disabled={disabled}
            onClick={this.onUpdateCart(updateCart)}
          >
            Done
          </Button>
        </div>
      </div>
    );
  }

  renderDesignNote(props: {
    cartProduct: CartProductWithQuotes;
    cartItems: CartItem[];
  }) {
    const { cartProduct, cartItems } = props;
    const { onUpdateDesignNote } = this.props;
    const item = cartItems.find((cartItem) => {
      return cartProduct.designId === cartItem.designId;
    });
    return (
      <>
        <div className="bwt-1 bc-navy-200 bgc-gray-50 p-1">
          <TextArea
            value={_.get(item, 'design.userNote')}
            placeholder="Add Special Note (e.g. special artwork instruction, background removal, pms colors, etc.)"
            resize={true}
            autoFocus={true}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              onUpdateDesignNote({ designId: cartProduct.designId, value: e.target.value });
            }}
          />
        </div>
        <div
          className="bc-navy-200 bwt-1 brb cursor-pointer py-p75 ta-center fw-bold"
          onClick={this.changeProductTab(null)}
        >
          Done
        </div>
      </>
    );
  }

  render() {
    const { cartProductWithQuotes, error, cartItems } = this.props;
    const { sizesQuantities, cartItemTab, hoverImageIndex } = this.state;
    const showSizes = cartItemTab === 'size';
    const showDesignNote = cartItemTab === 'note';

    const product: Product = cartProductWithQuotes.product;
    const productName: string = product.name;
    const productBrand: string = (product.categories.find((category) => {
      return category.scope === PRODUCT_CATEGORY_SCOPES.BRAND;
    }) || { name: ''}).name;
    const colorName: string = cartProductWithQuotes.colorId; // color Id is just the name in lower case
    const mockupUrl: string = cartProductWithQuotes.mockupUrl;
    const mockups = _.sortBy(cartProductWithQuotes.mockups, (mockup) => { return SIDES_BY_ORDER.indexOf(mockup.side) }) || [];
    const totalQuantity: number = getCartProductTotalQuantity(cartProductWithQuotes);
    const preCheckoutSubtotal: number = cartProductWithQuotes.quotes.reduce(
      (currentSubtotal: number, currentQuote: RequestCompleteQuoteOutput) => {
        const {
          amounts: { subtotal, shipping, discount },
        } = currentQuote;
        const cartQuoteWithoutSubtotal = subtotal - shipping + discount; // subtract shipping (normally added), add back discount (normally subtracted)
        return currentSubtotal + cartQuoteWithoutSubtotal;
      },
      0
    );
    const pricePerPiece: number = Math.floor(preCheckoutSubtotal / totalQuantity);

    const totalQuantities: number = Object.values(sizesQuantities).reduce(
      (total: number, currentSizeQuantity: number) => {
        return total + currentSizeQuantity;
      },
      0
    );
    const currentColor: ProductColor = product.colors.find((color: ProductColor) => {
      return color.name.toLowerCase() === colorName.toLowerCase();
    }) as ProductColor;

    const availableSizes = currentColor.sizesObjects as ProductSize[];
    const sizeDisplays = _.compact(availableSizes.map((availableSize) => {
      if (!cartProductWithQuotes.sizesQuantities[availableSize.name]) return null;
      return `${prettyProductSize(availableSize.name)}-${cartProductWithQuotes.sizesQuantities[availableSize.name]}`;
    }));

    const anchorData = _.get(this.imageRefs, `${hoverImageIndex}.current`)
      ? computeCoordinates(this.imageRefs[hoverImageIndex])
      : null;

    return (
      <MutationUpdateCart mutation={MUTATION_UPDATE_CART}>
        {(updateCart, { error: updateCartError, loading, data }) => {
          const disabled: boolean = loading || totalQuantities === 0;
          return (
            <div className="w-full fs-ms bc-navy-200 bw-1 br brb-1 p-relative">
              <style jsx={true}>
                {`
                  .fs-18 {
                    font-size: 18px;
                  }
                  .fs-14 {
                    font-size: 14px;
                  }
                `}
              </style>
              <div
                className={classnames(['product-list-details w-full flex p-relative bgc-white fs-xs'], {
                  br: !error,
                  brt: error,
                })}
              >
                <div className="p-p5 lg:p-p75 lg:flex-no-shrink lg:flex-no-grow lg:flex-basis-auto lg:w-auto">
                  { mockups.length <= 1 ?
                    <div
                      ref={this.imageRefs[0]}
                      className="image-container br"
                      style={{ height: '112px', width: '92px' }}
                      onMouseEnter={() => {this.setHoverImageIndex(0)}}
                      onMouseLeave={() => {this.setHoverImageIndex(-1)}}
                    >
                      <Image src={mockupUrl} className="br h-full" />
                    </div>
                    :
                    <div>
                      <div
                        ref={this.imageRefs[0]}
                        className="image-container br"
                        style={{ height: '112px', width: '92px' }}
                        onMouseEnter={() => {this.setHoverImageIndex(0)}}
                        onMouseLeave={() => {this.setHoverImageIndex(-1)}}
                      >
                        <Image src={mockups[0].mockupUrl} className="br h-full" />
                      </div>
                      <div className="-mx-2px flex mt-p25">
                        {mockups.map((mockup, mockupIndex) => {
                          if (mockupIndex > 0) {
                            return (
                              <div
                                ref={this.imageRefs[mockupIndex]}
                                className="mx-2px image-container br d-ib"
                                style={{ height: '34px', width: '28px' }}
                                onMouseEnter={() => {this.setHoverImageIndex(mockupIndex)}}
                                onMouseLeave={() => {this.setHoverImageIndex(-1)}}
                              >
                                <Image src={mockup.mockupUrl} className="br h-full" />
                              </div>
                            )
                          };
                        })}
                      </div>
                    </div>
                  }
                  {hoverImageIndex >= 0 && anchorData && (
                    <div>
                      <Popover
                        anchorData={anchorData}
                        position="right"
                        style="custom"
                        onMouseEnter={() => {}}
                        onMouseLeave={() => {}}
                      >
                        <div style={{
                          width: '345px',
                          height: '420px',
                          boxShadow: "0 36px 57px 4px rgba(0,0,0,0.14), 0 13px 69px 12px rgba(0,0,0,0.12), 0 17px 23px -11px rgba(0,0,0,0.2)",
                        }}>
                          <Image src={mockups[hoverImageIndex].mockupUrl} className="br w-full" />
                        </div>
                      </Popover>
                    </div>
                  )}
                </div>
                <div className="w-full py-p5 pr-p5 pb-25 flex flex-col justify-between lg:p-p75 lg:pb-p5 lg:flex-no-shrink lg:flex-grow lg:flex-basis-auto lg:w-auto">
                  <div className="flex-row">
                    <p className="fs-xs color-navy capitalize fw-bold">{productName}</p>
                    <div className="mt-p25 lg:mt-p5">
                      <p className="lg:d-ib">
                        <span className="fs-xs color-navy-700 capitalize">{colorName}</span>
                      </p>
                    </div>
                    <div className="mt-p25 lg:mt-p5">
                      <p className="lg:d-ib">
                        <span className="fs-xs color-navy-700 capitalize">Sizes{`(${totalQuantity})`}: {sizeDisplays.join(', ')}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between mt-p5 lg:mt-p75">
                    <PriceDisplayer
                      label={'Unit Price'}
                      numUnits={1}
                      price={pricePerPiece}
                      priceClassName="fs-md"
                      unitsClassName="d-n fs-xs color-gray-700 fw-bold"
                      labelClassName="fs-xs fw-bold color-navy-700"
                    />
                    <div className="flex items-center">
                      <PriceDisplayer
                        label={'Subtotal'}
                        numUnits={1}
                        price={preCheckoutSubtotal}
                        priceClassName="fs-md"
                        unitsClassName="d-n fs-xs color-gray-500 fw-bold"
                        labelClassName="ta-right fs-xs fw-bold color-navy-700"
                      />
                    </div>
                  </div>
                </div>
                <button className="p-absolute pin-t pin-r p-p25 lg:p-p5" onClick={this.onRemoveItemClick(updateCart)}>
                  {loading ?
                    <Loading
                      size="small"
                      textClassName="d-n"
                      paddingClassName="p-0"
                    />
                    :
                    <i className="mdi mdi-close color-gray-500 fs-icon-1p5" />
                  }
                </button>
              </div>
              <div
                  className="flex bwt-1 bc-navy-200"
                >
                <Button
                  type="text"
                  className="w-1/3 fw-bold color-gray-700 py-p5 bwr-1 bc-navy-200"
                  color="secondary"
                  corners="sharp"
                  size="md"
                  onClick={this.onEditDesignClick()}
                >
                  <span className="fs-14">Edit Design</span>
                </Button>
                <Button
                  type="text"
                  className={classnames("w-1/3 fw-bold py-p5 bwr-1 bc-navy-200", {
                    'bgc-gray-50 color-primary': showSizes,
                    'color-gray-700': !showSizes
                  })}
                  color="secondary"
                  size="md"
                  corners="sharp"
                  onClick={
                    showSizes ?
                    this.onCancelClick() :
                    this.onEditSizesClick(true)
                  }
                >
                  <span className="d-ib lg:d-n fs-14">Edit Qty - {totalQuantity}</span>
                  <span className="d-n lg:d-ib fs-14">Edit Quantity - {totalQuantity}</span>
                </Button>
                <Button
                  type="text"
                  className={classnames("w-1/3 fw-bold color-gray-700 py-p5", {
                    'bgc-gray-50 color-primary': showDesignNote,
                    'color-gray-700': !showDesignNote
                  })}
                  color="secondary"
                  corners="sharp"
                  size="md"
                  onClick={this.changeProductTab('note')}
                >
                  <span className="fs-14">Add Special Note</span>
                </Button>

              </div>
              {showSizes && (
                this.renderSizeQuantitySelector({
                  id: cartProductWithQuotes.id,
                  product,
                  productBrand,
                  availableSizes,
                  sizesQuantities,
                  loading,
                  disabled,
                  updateCart,
                })
              )}
              {showDesignNote && (
                this.renderDesignNote({
                  cartProduct: cartProductWithQuotes,
                  cartItems,
                })
              )}
              {this.renderErrorMessage(updateCart)}
            </div>
          );
        }}
      </MutationUpdateCart>
    );
  }
}

export default withRouter(CartProductItem);
