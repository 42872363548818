import React from 'react';
import config from 'inkp-config';

interface Props { };

const INKPOP_LOGO_WITH_SHADOW = 'https://inkp-production.32pt.com/public/assets/inkpop-logo-with-shadow.svg';

const HappinessGuaranteed: React.FunctionComponent<Props> = () => {
  return (
    <div className="px-1 py-1p5 lg:p-1p5 w-full br-p5 overflow-hidden bc-navy-200 bw-1 ta-left p-relative">
      <p className="mb-p5 fs-lg color-navy fw-bold">
        Happiness Guaranteed
      </p>
      <p className="m-0 fs-sm md:fs-md">
        1. Guaranteed On Time Delivery
      </p>
      <p className="m-0 fs-sm md:fs-md">
        2. Professional Design Review
      </p>
      <p className="m-0 fs-sm md:fs-md">
        3. All-Inclusive Pricing
      </p>
      <div className="p-absolute pin-r pin-t -mr-1 mt-1p5 lg:-mr-p5" style={{ height: '134px' }}>
        <img
          className="w-full h-full"
          src={INKPOP_LOGO_WITH_SHADOW}
          alt="Happiness Guaranteed"
        />
      </div>
    </div>
  );
};


export default HappinessGuaranteed;
