interface ShippingCodeMappingInterface {
  [key: string]: string;
}

export const ShippingCodeMapping: ShippingCodeMappingInterface = {
  NINE_DAY: '9 Day Standard',
  THREE_DAY: '3 Day Super Fast',
  FOUR_DAY: '4 Day Fast',
};
export const ShippingCodePercentageMapping: ShippingCodeMappingInterface = {
  NINE_DAY: 'Free',
  THREE_DAY: '20%',
  FOUR_DAY: '10%',
};

export enum CHECKOUT_STEPS {
  CONTACT,
  SHIPPING,
  PAYMENT,
  REVIEW,
}

export enum PLACE_ORDER_ERROR_CODES {
  INVALID_MINIMUM_DTG_QUANTITY = "INVALID_MINIMUM_DTG_QUANTITY",
  INVALID_MAXIMUM_QUANTITY = "INVALID_MAXIMUM_QUANTITY",
  NOT_ABLE_TO_PAY_ORDER = "NOT_ABLE_TO_PAY_ORDER",
  STALE_QUOTE_EXPIRED_COUPON = "STALE_QUOTE_EXPIRED_COUPON",
  STALE_QUOTE = "STALE_QUOTE",
  UNKNOWN = "UNKNOWN",
}

export const CHECKOUT_STEP_MAP = {
  [CHECKOUT_STEPS.CONTACT]: 'contact',
  [CHECKOUT_STEPS.SHIPPING]: 'shipping',
  [CHECKOUT_STEPS.PAYMENT]: 'payment',
  [CHECKOUT_STEPS.REVIEW]: 'review',
};
