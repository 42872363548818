import _ from 'lodash';
import React from 'react';
import { DataProxy } from 'apollo-cache';
import { HostedFieldsTokenizePayload, BraintreeError } from 'braintree-web';
import { validateShippingParams } from 'inkp-order-sdk/cart';
import {
  Query as QueryOrderResults,
  Mutation as MutationUpdateCartResults,
  Cart,
  NullableAddress,
  AmountsInput,
  RequestCompleteQuoteOutput,
  QueryrequestCartQuoteArgs,
} from 'inkp-order-sdk/types.g';
import { US_STATES_HASH_MAP } from 'inkp-order-sdk/constants';
import { PAYMENT_METHOD_ENUM } from 'inkp-order-sdk/types.g';
import { User } from 'inkp-user-sdk/types.g';
import { HostedFieldsProvider, WithBrainTree } from 'sp-hosted-fields';
import config from 'inkp-config/public';

import Input from 'inkp-components/dist/Components/Input';
import Select from 'inkp-components/dist/Components/Select';
import Button from 'inkp-components/dist/Components/Button';
import Loading from 'inkp-components/dist/Components/Loading';
import Alert from 'inkp-components/dist/Components/Alert';

import PaymentForm from './PaymentForm';
import {
  MutationUpdateCart,
  MUTATION_UPDATE_CART,
  updateCurrentCart,
  QueryRequestCartQuote,
  QUERY_REQUEST_CART_QUOTE,
  QUERY_CART_BY_USER,
} from '../../../../util/Checkout';
import { MUTATION_PLACE_ORDER, updateOrderById, MutationPlaceOrder } from '../../../../util/orders';
import { PLACE_ORDER_ERROR_CODES } from '../../constants';
import PlaceOrderError from './PlaceOrderError';

// GTM helpers
import GTM from '../../../../util/gtm';
import { GTMTypes, GTMEvents } from '../../../../interfaces/GTM';
import { COUPON_SOURCE_ENUM } from 'inkp-coupon-sdk/types.g';
import { ApolloQueryResult } from 'apollo-client';
import { findServiceError } from '../../../../util/apollo';


interface InputValues {
  value: string;
  touched: boolean;
}
interface Props {
  cart: Cart;
  user: User;
  onNext: (orderId: string) => void;
}
interface State {
  [id: string]: any;
  hostedFieldsError: BraintreeError | null;
  billingSameAsShipping: boolean;
  name: InputValues;
  address1: InputValues;
  address2: InputValues;
  city: InputValues;
  state: InputValues;
  zip: InputValues;
  country: InputValues;

  validForm: boolean;

  isPlacingOrder: boolean;
  placeOrderError: null | PLACE_ORDER_ERROR_CODES;
}

export default class CartPayment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { billingAddress = {} } = props.cart;

    let name = billingAddress.name || '';
    let address1 = billingAddress.address1 || '';
    let address2 = billingAddress.address2 || '';
    let city = billingAddress.city || '';
    let state = billingAddress.state || '';
    let zip = billingAddress.zip || '';
    let country = billingAddress.country || 'United States';

    this.state = {
      validForm: false,
      hostedFieldsError: null,
      billingSameAsShipping: true,
      isPlacingOrder: false,
      name: { value: name, touched: !!name },
      address1: { value: address1, touched: !!address1 },
      address2: { value: address2, touched: !!address2 },
      city: { value: city, touched: !!city },
      state: { value: state, touched: !!state },
      zip: { value: zip, touched: !!zip },
      country: { value: country, touched: !!country },

      placeOrderError: null,
    };
  }

  onPlaceOrderError = (error: any) => {
    this.setState({ isPlacingOrder: false });
    const err = findServiceError(error);
    const code = _.get(err, 'extensions.code');
    if (code && Object.values(PLACE_ORDER_ERROR_CODES).indexOf(code) > -1) {
      return this.setState({ placeOrderError: code });
    }

    return this.setState({ placeOrderError: PLACE_ORDER_ERROR_CODES.UNKNOWN });
  };

  onClearPlaceOrderError = () => {
    this.setState({ placeOrderError: null });
  };

  onRemoveCoupon = (updateCart: any, cartQuote: RequestCompleteQuoteOutput[], cartId: string) => () => {
    const codes = _.filter(cartQuote[0].couponResponse, (couponResponse) => {
      return (couponResponse.source as COUPON_SOURCE_ENUM) !== COUPON_SOURCE_ENUM.USER;
    });
    return updateCart({ variables: { id: cartId, data: { coupons: codes } } });
  };

  onRefreshQuote = (
    refreshQuote: (variables?: QueryrequestCartQuoteArgs) => Promise<ApolloQueryResult<QueryOrderResults>>
  ) => () => {
    refreshQuote().then(() => {
      this.onClearPlaceOrderError();
    });
  };

  onFormValid = () => (event: boolean) => {
    this.setState({ validForm: event });
  };

  setBillingSameAsShipping = () => (event: React.MouseEvent<HTMLSpanElement>) => {};

  renderErrorsForField(validationErrors: any) {
    if (_.isEmpty(validationErrors)) return null;

    return validationErrors.map(({ message }: { message: string }, index: number) => (
      <div key={index}>
        {message && (
          <div className="mt-p5 ml-1 fs-xs fw-bold color-red">
            <i className="mdi mdi-alert fs-sm-icon color-red pr-p25" />
            {message}
          </div>
        )}
      </div>
    ));
  }

  renderAlert() {
    return (
      <Alert
        alerts={[
          {
            type: 'error',
            title: 'Checkout error',
            content:
              'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
          },
        ]}
      />
    )
  }

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    const {
      name,
      address1,
      address2,
      zip,
      state,
      country,
      city,
      billingSameAsShipping,
      hostedFieldsError,
      isPlacingOrder,
      placeOrderError,
    } = this.state;
    const { cart, user } = this.props;
    const { shippingAddress, id: cartId } = cart;

    const fieldsToValidate = {
      name: name.value,
      address1: address1.value,
      address2: address2.value,
      city: city.value,
      state: state.value,
      zip: zip.value,
      country: country.value,
    }

    let billingAddress: NullableAddress = {};
    if (billingSameAsShipping && shippingAddress) {
      billingAddress = _.omit(shippingAddress, ['phone']);
    } else {
      billingAddress = {
        name: name.value,
        address1: address1.value,
        address2: address2.value,
        city: city.value,
        state: state.value,
        zip: zip.value,
        country: country.value,
      };
    }

    return (
      <QueryRequestCartQuote
        query={QUERY_REQUEST_CART_QUOTE}
        variables={{ cartId }}
        fetchPolicy="network-only"
      >
        {({
          error: requestCartQuoteError,
          loading: requestCartQuoteLoading,
          data: requestCartQuoteData,
          refetch: refetchQuote,
        }) => {
          if (requestCartQuoteError) {
            console.error(requestCartQuoteError || "Couldn't fetch information for the Cart Quote");
            return this.renderAlert();
          }

          const cartQuote = requestCartQuoteData && requestCartQuoteData.requestCartQuote || [];
          const inputAmounts: AmountsInput[] = cartQuote.map((currentItemQuote) => {
            return {
              blanks: currentItemQuote.amounts.blanks,
              printing: currentItemQuote.amounts.printing,
              shipping: currentItemQuote.amounts.shipping,
              upcharge: currentItemQuote.amounts.upcharge,
              subtotal: currentItemQuote.amounts.subtotal,
              discount: currentItemQuote.amounts.discount,
              adjustment: currentItemQuote.amounts.adjustment,
              tax: currentItemQuote.amounts.tax,
              total: currentItemQuote.amounts.total,
            };
          });

          return (
            <MutationUpdateCart
              mutation={MUTATION_UPDATE_CART}
              onError={(err) => {
                this.setState({ isPlacingOrder: false });
              }}
            >
              {(updateCart, { error: updateCartError, loading: updateCartLoading, data: updateCartData }) => {
                const validationErrorsArray = !billingSameAsShipping ? validateShippingParams(fieldsToValidate) : [];
                const validationErrors = _.groupBy(validationErrorsArray, 'field');
                const isDisabled: boolean = !this.state.validForm || validationErrorsArray.length > 0;

                return (
                  <HostedFieldsProvider authorization={config.braintree.tokenizationKey}>
                    <WithBrainTree
                      render={(braintree) => {
                        return (
                          <div className="mx-1 md:mx-0">
                            <div className="color-primary fw-extra-bold">Step 3</div>
                            <div className="flex justify-between fs-lg md:fs-xl fw-extra-bold mt-p25">
                              <span>Payment Info</span>
                              <span className="flex items-center">
                                <img
                                  className="ml-p75 h-1p25"
                                  src="https://inkp-production.32pt.com/public/assets/ssl+secured+seal%402x.svg"
                                  alt=""
                                />
                                <img
                                  className="ml-p75 h-1p5"
                                  src="https://inkp-production.32pt.com/public/assets/DigiCertSecuredSeal-153x86.svg"
                                  alt=""
                                />
                              </span>
                            </div>
                            {hostedFieldsError && <div>{hostedFieldsError.message}</div>}

                            <div className="mt-1p5 md:mt-2">
                              <PaymentForm hostedFieldsError={hostedFieldsError} onValid={this.onFormValid()} />
                            </div>

                            <div className="mt-1p5">
                              <div className="fs-md fw-extra-bold">Billing Address</div>
                              <div className="mt-p5">
                                <span
                                  className="color-navy-700 cursor-pointer"
                                  onClick={() => this.setState({ billingSameAsShipping: !billingSameAsShipping })}
                                >
                                  <i className={`mdi mdi-checkbox-${billingSameAsShipping ? 'marked' : 'blank-outline'} fs-icon-1p5`} />
                                  <span className="ml-p5 fw-bold">Billing address same as shipping</span>
                                </span>
                              </div>
                              {!billingSameAsShipping && (
                                <div>
                                  <Input
                                    className="mt-2"
                                    label="Full Name"
                                    value={name.value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      this.setState({
                                        name: {
                                          value: event.target.value,
                                          touched: true,
                                        },
                                      });
                                    }}
                                    error={(updateCartError || name.touched) && !!validationErrors.name}
                                    helper={(updateCartError || name.touched) && this.renderErrorsForField(validationErrors.name)}
                                  />
                                  <Input
                                    className="mt-1p5"
                                    label="Street Address"
                                    value={address1.value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      this.setState({
                                        address1: {
                                          value: event.target.value,
                                          touched: true,
                                        },
                                      });
                                    }}
                                    error={(updateCartError || address1.touched) && !!validationErrors.address1}
                                    helper={(updateCartError || address1.touched) && this.renderErrorsForField(validationErrors.address1)}
                                  />
                                  <Input
                                    className="mt-1p5"
                                    label="Apt, Suite, Building, etc"
                                    value={address2.value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                      this.setState({
                                        address2: {
                                          value: event.target.value,
                                          touched: true,
                                        },
                                      })
                                    }
                                  />
                                  <div className="flex -mx-p5 mt-1p5">
                                    <div className="w-1/2 px-p5">
                                      <Input
                                        label="City"
                                        value={city.value}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                          this.setState({
                                            city: {
                                              value: event.target.value,
                                              touched: true,
                                            },
                                          })
                                        }
                                        error={(updateCartError || city.touched) && !!validationErrors.city}
                                        helper={(updateCartError || city.touched) && this.renderErrorsForField(validationErrors.city)}
                                      />
                                    </div>
                                    <div className="w-1/2 px-p5">
                                      <Select
                                        placeholder="State"
                                        label="State"
                                        labelColor="color-gray-700"
                                        selected={state.value || null}
                                        options={_.toPairs(US_STATES_HASH_MAP).map(([value, display]) => ({
                                          value,
                                          display,
                                        }))}
                                        onChange={(e) =>
                                          this.setState({
                                            state: {
                                              value: e.target.value,
                                              touched: true,
                                            },
                                          })
                                        }
                                      />
                                      {(updateCartError || state.touched) && this.renderErrorsForField(validationErrors.state)}
                                    </div>
                                  </div>
                                  <div className="flex -mx-p5 mt-1p5">
                                    <div className="w-1/2 px-p5">
                                      <Input
                                        className="w-full"
                                        label="Zip Code"
                                        value={zip.value}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                          this.setState({
                                            zip: {
                                              value: event.target.value,
                                              touched: true,
                                            },
                                          })
                                        }
                                        error={(updateCartError || zip.touched) && !!validationErrors.zip}
                                        helper={(updateCartError || zip.touched) && this.renderErrorsForField(validationErrors.zip)}
                                      />
                                    </div>
                                    <Input
                                      label="Country"
                                      defaultValue={'United States'}
                                      className="w-1/2 px-p5"
                                      disabled={true}
                                    ></Input>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="flex flex-row-reverse mt-1p5">
                              <div className="next-step-button flex-none">
                                <MutationPlaceOrder
                                  mutation={MUTATION_PLACE_ORDER}
                                  refetchQueries={[{ query: QUERY_CART_BY_USER }]}
                                  onError={this.onPlaceOrderError}
                                >
                                  {(placeOrder) => {
                                    return (
                                      <Button
                                        size="xl"
                                        corners="full"
                                        block={true}
                                        disabled={isDisabled || isPlacingOrder || requestCartQuoteLoading}
                                        loading={isPlacingOrder || requestCartQuoteLoading}
                                        onClick={() => {
                                          this.setState({ isPlacingOrder: true });
                                          braintree.tokenize((err, result: HostedFieldsTokenizePayload) => {
                                            if (err) {
                                              this.setState({ isPlacingOrder: false });
                                              return this.setState({ hostedFieldsError: err });
                                            }

                                            const last4 = _.get(result, 'details.lastFour');

                                            return updateCart({
                                              variables: {
                                                id: cartId,
                                                data: {
                                                  chargeToken: result.nonce,
                                                  billingAddress,
                                                  billingDetails: {
                                                    method: PAYMENT_METHOD_ENUM.BRAINTREE,
                                                    token: result.nonce,
                                                    last4,
                                                  },
                                                },
                                              },
                                              update: (cache: DataProxy, { data }: { data: MutationUpdateCartResults }) => {
                                                updateCurrentCart(cache, data.updateCart);
                                                placeOrder({
                                                  variables: { cartId, amounts: inputAmounts },
                                                  update: (cache, { data }) => {
                                                    if (data) {
                                                      const { placeOrder: { id } } = data;
                                                      updateOrderById(cache, data.placeOrder);
                                                      GTM.push(GTMTypes.CART);
                                                      GTM.push(GTMTypes.ORDER, { orderId: id });
                                                      GTM.fire(GTMEvents.PURCHASE);
                                                      this.props.onNext(id);
                                                    }
                                                  },
                                                });
                                              },
                                            });
                                          });
                                        }}
                                      >
                                        <span style={{ fontSize: '18px', lineHeight: '28px' }}>Pay & Place Order</span>
                                      </Button>
                                    );
                                  }}
                                </MutationPlaceOrder>
                              </div>
                            </div>
                          
                            {placeOrderError && (
                              <MutationUpdateCart
                                mutation={MUTATION_UPDATE_CART}
                                update={(cache: DataProxy, { data }: { data: MutationUpdateCartResults }) => {
                                  updateCurrentCart(cache, data.updateCart);
                                }}
                                refetchQueries={[{ query: QUERY_REQUEST_CART_QUOTE, variables: { cartId } }]}
                                onCompleted={this.onClearPlaceOrderError}
                              >
                                {(updateCart) => {
                                  return (
                                    <PlaceOrderError
                                      error={placeOrderError!}
                                      onClearError={this.onClearPlaceOrderError}
                                      onRemoveCoupon={this.onRemoveCoupon(updateCart, cartQuote, cartId)}
                                      onRefreshQuote={this.onRefreshQuote(refetchQuote)}
                                    />
                                  );
                                }}
                              </MutationUpdateCart>
                            )}
                          </div>
                            
                        );
                      }}
                    />
                  </HostedFieldsProvider>
                );
              }}
            </MutationUpdateCart>
          );
        }}
      </QueryRequestCartQuote>
    );
  }
}

