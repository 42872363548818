import React from 'react';
import classnames from 'classnames';
import Helmet from 'react-helmet';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import _ from 'lodash';

import { Query as QueryUserResults } from 'inkp-user-sdk/types.g';
import { path, routes } from 'inkp-routes/public';
import { Query as QueryOrderResults, CartItem } from 'inkp-order-sdk/types.g';
import { Query as QueryProductResults, Product } from 'inkp-product-sdk/types.g';
import { parseProductItemId } from 'inkp-product-sdk';
import Loading from 'inkp-components/dist/Components/Loading';
import Alert from 'inkp-components/dist/Components/Alert';

import { QUERY_CURRENT_USER, QUERY_CART_BY_USER, PRODUCTS_QUERY } from '../../util/Checkout';
import Cart from './Cart';
import Checkout from './Checkout';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

class QueryCurrentUser extends Query<QueryUserResults> {}
class QueryCartByUser extends Query<QueryOrderResults> {}
class QueryProducts extends Query<QueryProductResults> {}

export default class CartCheckout extends React.Component<RouteComponentProps> {
  renderError() {
    return (
      <Alert
        alerts={[
          {
            type: 'error',
            title: 'Checkout error',
            content: 'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
          },
        ]}
      />
    );
  }

  renderLoading() {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loading size="large" />
      </div>
    );
  }

  render() {
    const { location } = this.props;

    const isCheckout: boolean = location.pathname === routes.app.checkout.base;

    return (
      <React.Fragment>
        <Helmet>
          <title>{isCheckout ? 'Inkpop Checkout' : 'Inkpop Cart'}</title>
          <meta
            name="description"
            content="Choose Inkpop for your custom shirt printing needs. Our Happiness Guarantee ensures your satisfaction with both print quality and on-time delivery"
          />
        </Helmet>
        <QueryCurrentUser query={QUERY_CURRENT_USER}>
          {({ error: currentUserError, loading: currentUserLoading, data: currentUserData }) => {
            if (currentUserLoading) return this.renderLoading();
            if (currentUserError || !currentUserData || !currentUserData.currentUser) return this.renderError();

            const { currentUser } = currentUserData;

            return (
              <QueryCartByUser query={QUERY_CART_BY_USER} fetchPolicy="network-only">
                {({ error: cartByUserError, loading: cartByUserLoading, data: cartByUserData }) => {
                  if (cartByUserLoading) return this.renderLoading();
                  if (cartByUserError || !cartByUserData) return this.renderError();

                  const { cartByUser } = cartByUserData;

                  if (!cartByUser || cartByUser.items.length === 0) {
                    GTM.push(GTMTypes.CART);
                    return (
                      <div className="w-full lg:w-container p-2 lg:p-4 lg:mx-auto">
                        <h1 className="w-full">Your cart is empty!</h1>
                        <Link className="w-full d-b" to={path(routes.app.product.category, { category: 't-shirts' })}>
                          <h3>Let's add some products.</h3>
                        </Link>
                      </div>
                    );
                  }

                  const productIds = _.uniq(
                    _.compact(
                      _.map(cartByUser.items, (cartItem: CartItem) => {
                        const { productId } = parseProductItemId(cartItem.product.productItemId);
                        return productId;
                      })
                    )
                  );

                  return (
                    <QueryProducts query={PRODUCTS_QUERY} variables={{ ids: productIds }}>
                      {({ error: productsByIdError, loading: productsByIdLoading, data: productsByIdData }) => {
                        if (productsByIdLoading) return this.renderLoading();
                        if (productsByIdError || !productsByIdData) return this.renderError();

                        const { productsById: products } = productsByIdData;

                        if (_.isEmpty(products)) return this.renderError();

                        if (isCheckout)
                          return <Checkout cart={cartByUser} user={currentUser} products={products as Product[]} />;
                        return <Cart cart={cartByUser} user={currentUser} products={products as Product[]} />;
                      }}
                    </QueryProducts>
                  );
                }}
              </QueryCartByUser>
            );
          }}
        </QueryCurrentUser>
      </React.Fragment>
    );
  }
}
