import React from 'react';
import _ from 'lodash';
import { DataProxy } from 'apollo-cache';
import { Mutation, MutationFn } from 'react-apollo';
import { ApolloError } from 'apollo-client';

import { User, Mutation as UserMutationResults } from 'inkp-user-sdk/types.g';
import { validateUpdateDetailsParams, formatToPhone, isUserRegistered } from 'inkp-user-sdk/user';
import { Cart, Mutation as OrderMutationResults, MutationupdateCartArgs, CartFeatures } from 'inkp-order-sdk/types.g';
import { Design, ShareableInput, Mutation as DesignMutationResults } from 'inkp-design-sdk/types.g';
import { routes } from 'inkp-routes';

import Input from 'inkp-components/dist/Components/Input';
import Button from 'inkp-components/dist/Components/Button';
import Loading from 'inkp-components/dist/Components/Loading';
import Modal from 'inkp-components/dist/Components/Modal';
import Alert from 'inkp-components/dist/Components/Alert';
import Popover from 'inkp-components/dist/Components/Popover';
import usePopoverAnchor from 'inkp-components/dist/hooks/usePopoverAnchor';

import { MUTATION_UPDATE_CART } from '../../../../util/Checkout';
import {
  MUTATION_UPDATE_USER,
  MutationUpdateUser,
  updateCurrentUser,
  MUTATION_SIGN_IN,
  MutationSignIn,
} from '../../../../graphql/users';
import { MUTATION_UPDATE_SHAREABLES, MutationUpdateShareables } from '../../../../graphql/shareable';
import { findServiceError } from '../../../../util/apollo';

import ClosedStep from '../ClosedStep';
import ArtworkApproval from './ArtworkApproval';

// GTM helpers
import GTM from '../../../../util/gtm';
import { GTMTypes } from '../../../../interfaces/GTM';

interface Props {
  user: User;
  cart: Cart;
  onNext: () => void;
}

interface ContactInput {
  value: string;
}

interface ContactState {
  name: ContactInput;
  email: ContactInput;
  phone: ContactInput;
  password: ContactInput;
  features: CartFeatures | undefined;
  isEmailTaken: boolean;
  shouldUpdateShareables: boolean;
  errors: { message?: string; field: string; }[];
}

const CONTACT_INFO_BLURB =
  'We will use this information to send proofs of the order, and contact you if we have any questions with the order.';

class UpdateCartMutation extends Mutation<OrderMutationResults, MutationupdateCartArgs> {}

const getShareableIdsFromCart = (cart: Cart): string[] => {
  const shareableIds: string[] = [];
  cart.items.forEach((item) => {
    const { design } = item;
    if (design) {
      const { shareable } = design as Design;
      if (shareable) {
        shareableIds.push(shareable.id);
      }
    }
  });

  return _.uniq(shareableIds);
};

const Contact: React.FunctionComponent<Props> = (props: Props) => {
  React.useEffect(() => {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }, []);
  const { cart, user } = props;
  const [state, setState] = React.useState<ContactState>({
    name: {
      value: user.name || '',
    },
    email: {
      value: user.email || '',
    },
    password: {
      value: '',
    },
    phone: {
      value: user.phone || '',
    },
    features: {
      ...cart.features,
      requiresCustomerApproval: false,
    },
    isEmailTaken: false,
    shouldUpdateShareables: false,
    errors: [],
  });
  const [iconHover, setIconHover] = React.useState(false);
  const [tooltipHover, setTooltipHover] = React.useState(false);

  const { name, email, phone, features, isEmailTaken, password, shouldUpdateShareables, errors } = state;

  const renderErrorsForField = (validationErrors: any) => {
    if (_.isEmpty(validationErrors)) return null;

    return validationErrors.map(({ message }: { message: string }, index: number) => (
      <div key={index}>
        {message && (
          <div className="mt-p5 ml-1 fs-xs fw-bold color-red">
            <i className="mdi mdi-alert fs-sm-icon color-red pr-p25" />
            {message}
          </div>
        )}
      </div>
    ));
  };

  const fieldsToValidate = {
    name: name.value,
    email: email.value,
    phone: phone.value,
  };
  const tooltipRef = React.useRef<HTMLElement>(null);
  const anchorData = usePopoverAnchor(tooltipRef);

  const emailOnChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    return setState({
      ...state,
      email: {
        value: event.target.value,
      },
      errors: _.reject(errors, { field: 'email' }),
    });
  };

  const passwordOnChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    return setState({
      ...state,
      password: {
        value: event.target.value,
      },
    });
  };

  return (
    <MutationUpdateShareables
      mutation={MUTATION_UPDATE_SHAREABLES}
      onCompleted={(data: DesignMutationResults) => {
        return props.onNext();
      }}
    >
      {(
        updateShareables,
        {
          data: mutationUpdateShareablesData,
          error: mutationUpdateShareablesError,
          loading: mutationUpdateShareablesLoading,
        }
      ) => {
        return (
          <MutationUpdateUser
            mutation={MUTATION_UPDATE_USER}
            update={(cache: DataProxy, { data }: { data: UserMutationResults }) => {
              if (!isUserRegistered(user) && !user.email && data.updateUser.email) {
                setState({
                  ...state,
                  shouldUpdateShareables: true,
                });
              }
              updateCurrentUser(cache, data.updateUser);
            }}
            onCompleted={(data: UserMutationResults) => {
              const { updateUser } = data;
              // validate if we are updating an ANON user with no email to have email
              if (shouldUpdateShareables) {
                const shareableIds = getShareableIdsFromCart(cart);
                const shareablesInput: ShareableInput[] = shareableIds.map((shareableId) => {
                  return {
                    id: shareableId,
                    email: updateUser.email,
                  };
                });
                return updateShareables({
                  variables: {
                    shareablesInput,
                  },
                });
              }
              return props.onNext();
            }}
            onError={(error: ApolloError) => {
              const serviceError = findServiceError(error);
              if (serviceError && serviceError.extensions) {
                if (serviceError.extensions.code === 'TAKEN_EMAIL') {
                  setState({
                    ...state,
                    isEmailTaken: true,
                  });
                }
              }
            }}
          >
            {(
              updateUser,
              { data: updateUserData, error: updateUserError, loading: updateUserLoading, called: updateUserCalled }
            ) => {
              return (
                <UpdateCartMutation
                  mutation={MUTATION_UPDATE_CART}
                  onCompleted={(data: OrderMutationResults) => {
                    const { updateCart } = data;
                    return updateUser({
                      variables: {
                        userData: {
                          userId: user.id,
                          name: name.value,
                          email: email.value,
                          phone: phone.value,
                        },
                      },
                    });
                  }}
                >
                  {(updateCart, { loading: cartLoading, error: cartError }) => {
                    if (cartError) {
                      return (
                        <Modal width="464px" position="start" padding="pt-5 px-1 md:px-0" overlayColor="">
                          <Alert
                            alerts={[
                              {
                                type: 'error',
                                title: 'Checkout error',
                                content:
                                  'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
                              },
                            ]}
                          />
                        </Modal>
                      );
                    }

                    const groupedErrors = _.groupBy(errors, 'field');
                    const shippingAddressWithPhone = Object.assign({}, cart.shippingAddress, { phone: phone.value });
                    const disableEmailField = isUserRegistered(user) ? true : !!user.email ? true : false;

                    return (
                      <div className="mx-1 md:mx-0">
                        <div className="color-primary fw-extra-bold">Step 1</div>
                        <div className="fs-lg md:fs-xl fw-extra-bold mt-p25">Contact Info</div>
                        <div className="fs-md mt-p5 md:mt-1">{CONTACT_INFO_BLURB}</div>

                        <div id='checkout-contact-name' className="mt-2">
                          <Input
                            label="Full Name"
                            value={name.value}
                            onChange={(e) => {
                              setState({
                                ...state, 
                                name: {
                                  value: e.target.value,
                                },
                                errors: _.reject(errors, { field: 'name' }),
                              });
                            }}
                            error={!!groupedErrors.name}
                            helper={renderErrorsForField(groupedErrors.name)}
                          />
                        </div>
                        
                        <div id="checkout-contact-email" className="mt-1p5">
                          <Input
                            label="Email"
                            value={email.value}
                            disabled={disableEmailField}
                            inputType="email"
                            onChange={disableEmailField ? undefined : emailOnChange()}
                            error={!!groupedErrors.email}
                            helper={renderErrorsForField(groupedErrors.email)}
                          />
                        </div>

                        {isEmailTaken && (
                          <div id="checkout-contact-password" className="mt-p5 md:mt-1">
                            <div className="fs-md">
                              Looks like you already have an account. Please type your password to continue.
                            </div>
                            <Input
                              className="mt-1p5"
                              label="Password"
                              value={password.value}
                              inputType="password"
                              onChange={passwordOnChange()}
                            />
                          </div>
                        )}

                        <div id="checkout-contact-phone" className="md:w-7/10 xl:w-1/2 p-relative mt-1p5">
                          <Input
                            label="Phone Number"
                            value={phone.value}
                            inputType="text"
                            onChange={(e) => {
                              const formattedPhone = formatToPhone(e.target.value);
                              setState({
                                ...state,
                                phone: {
                                  value: formattedPhone,
                                },
                                errors: _.reject(errors, { field: 'phone' }),
                              });
                            }}
                            error={!!groupedErrors.phone}
                            helper={renderErrorsForField(groupedErrors.phone)}
                          />
                          <span
                            ref={tooltipRef}
                            className="p-absolute pin-t pin-r mr-p75 mt-p75"
                            onMouseEnter={() => setIconHover(true)}
                            onMouseLeave={() => setIconHover(false)}
                          >
                            <i className="mdi mdi-information-outline color-gray-700 lg-icon" />
                          </span>
                          {anchorData && (tooltipHover || iconHover) && (
                            <Popover
                              anchorData={anchorData}
                              position="top"
                              style="tooltip"
                              hoverable={true}
                              onMouseEnter={() => setTooltipHover(true)}
                              onMouseLeave={() => setTooltipHover(false)}
                            >
                              <div className="fs-xs w-12 xl:w-auto">
                                We may need to contact you with questions about your order.
                              </div>
                            </Popover>
                          )}
                        </div>

                        <div className="mt-1p5 md:mt-2">
                          <ArtworkApproval
                            features={features}
                            onApprovalAccepted={() => {
                              setState({
                                ...state,
                                features: {
                                  ...features,
                                  requiresCustomerApproval: true,
                                },
                              });
                            }}
                            onApprovalRejected={() => {
                              setState({
                                ...state,
                                features: {
                                  ...features,
                                  requiresCustomerApproval: false,
                                },
                              });
                            }}
                          />
                        </div>

                        <div className="flex flex-row-reverse mt-1p5">
                          <div className="next-step-button flex-none">
                            <Button
                              size="xl"
                              corners="full"
                              block={true}
                              disabled={
                                mutationUpdateShareablesLoading ||
                                cartLoading ||
                                updateUserLoading
                              }
                              loading={mutationUpdateShareablesLoading || updateUserLoading || cartLoading}
                              onClick={() => {
                                const errors = validateUpdateDetailsParams(fieldsToValidate);
                                setState({ ...state, errors });
                                if (errors.length > 0) {
                                  const elem = document.getElementById(`checkout-contact-${errors[0].field}`);
                                  if (elem) elem.scrollIntoView();
                                  return;
                                }
                                return updateCart({
                                  variables: {
                                    id: cart.id,
                                    data: {
                                      shippingAddress: shippingAddressWithPhone,
                                      features,
                                    },
                                  },
                                });
                              }}
                            >
                              <span style={{ fontSize: '18px', lineHeight: '28px' }}>Continue to Shipping</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </UpdateCartMutation>
              );
            }}
          </MutationUpdateUser>
        );
      }}
    </MutationUpdateShareables>
  );
};

export default Contact;
