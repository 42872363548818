import * as React from 'react';
import classnames from 'classnames';
import { SHIPPING_SPEED_ENUM, RequestCompleteQuoteOutput, CartCoupon } from 'inkp-order-sdk/types.g';
import { COUPON_SOURCE_ENUM } from 'inkp-coupon-sdk/types.g';
import Button from 'inkp-components/dist/Components/Button';

import { Coupon } from '../../../../interfaces/CartCheckout';
import { ShippingCodeMapping } from '../../constants';
import PromoCode from '../../../../components/PromoCode';

interface Props {
  subtotal: number;
  shipping: number;
  tax: number;
  total: number;
  shippingCode: SHIPPING_SPEED_ENUM | null;
  coupons: Coupon[];
  preTax: boolean;
  stateCode?: string;
  cartQuote: RequestCompleteQuoteOutput[];
  cartCoupons: CartCoupon[];
  onAddCoupon: (code: string) => void;
  onRemoveCoupon: (code: string) => void;
  showCoupons?: boolean;
  onToggleShowCoupons: () => void;
}

const formatPrice = (price: number): string => {
  return (price / 100).toFixed(2);
};

const Fields: React.FunctionComponent<Props> = ({
  subtotal,
  shipping,
  tax,
  total,
  coupons,
  shippingCode,
  preTax = false,
  stateCode,
  cartQuote,
  cartCoupons,
  onAddCoupon,
  onRemoveCoupon,
  showCoupons = false,
  onToggleShowCoupons,
}) => {
  const defaultShippingCode = shippingCode || SHIPPING_SPEED_ENUM.NINE_DAY;
  const userCoupon = coupons.filter((coupon) => {
    return coupon.source === COUPON_SOURCE_ENUM.USER;
  })[0];
  const internalCoupons = coupons.filter((coupon) => {
    return coupon.source === COUPON_SOURCE_ENUM.INTERNAL;
  });
  return (
    <div className="">
      <div className="flex justify-between">
        <div>Subtotal</div>
        <div>{`$${formatPrice(subtotal)}`}</div>
      </div>
      <div className="flex justify-between mt-p5">
        <div>{`Shipping (${ShippingCodeMapping[defaultShippingCode]})`}</div>
        <div>{shipping > 0 ? `$${formatPrice(shipping)}` : 'Free'}</div>
      </div>
      <div className="flex justify-between mt-p5">
        {stateCode ? <div>Tax ({stateCode})</div> : <div>Tax</div>}
        {tax > 0 && !preTax ?
          <div>${formatPrice(tax)}</div>
        : 
          <div className="color-navy-500">Calculated in checkout</div>
        }
      </div>
      {internalCoupons.map((coupon) => (
        <div key={coupon.code} className="flex justify-between mt-p5">
          <div>{coupon.description || coupon.displayCode || coupon.code}</div>
          <div>{`-$${formatPrice(coupon.discount)}`}</div>
        </div>
      ))}
      <div className="flex justify-between mt-p5">
        <div className="flex items-center">
          <span className="pr-p25">
            Promo Code {userCoupon ? (`(${userCoupon.description || userCoupon.displayCode || userCoupon.code})`) : ''}
          </span>
          {userCoupon ? 
            <Button
              type="text"
              color="secondary"
              size="lg"
              icon={true}
              withIcon={true}
              mdIcon="close-circle"
              className="fw-bold color-gray-700"
              noYPadding={true}
              noXPadding={true}
              onClick={() => onRemoveCoupon(userCoupon.code)}
            />       
          :
            <Button
              type="text"
              color="secondary"
              size="lg"
              icon={true}
              withIcon={true}
              mdIcon={showCoupons ? 'minus-circle-outline' : 'plus-circle-outline'}
              className="fw-bold color-gray-700"
              noYPadding={true}
              noXPadding={true}
              onClick={onToggleShowCoupons}
            />          
          }
        </div>
        {userCoupon && 
          <div>{`-$${formatPrice(userCoupon.discount)}`}</div>
        }
      </div>
      <div>
        <PromoCode
          coupons={cartCoupons}
          cartQuote={cartQuote}
          onApply={onAddCoupon}
          onRemove={onRemoveCoupon}
          showCoupon={showCoupons && !userCoupon}
        />
      </div>
      <div className={classnames("bwt-1 bc-navy-200", { "mt-1": !(showCoupons && !userCoupon) })}/>
      <div className="flex justify-between mt-1 fw-bold" style={{ fontSize: '18px' }}>
        {preTax ? <div>All-Inclusive Price</div> : <div>Total</div>}
        <div>{`$${formatPrice(total)}`}</div>
      </div>
    </div>
  );
};

export default Fields;
