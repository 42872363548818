import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { routes } from 'inkp-routes';

import { SHIPPING_SPEED_ENUM, Amounts, Cart, RequestCompleteQuoteOutput, CartCoupon } from 'inkp-order-sdk/types.g';
import { User } from 'inkp-user-sdk/types.g';
import { Coupon } from '../../../interfaces/CartCheckout';
import Button from 'inkp-components/dist/Components/Button';
import Loading from 'inkp-components/dist/Components/Loading';

import Fields from './Fields';

// helpers
import GTM from '../../../util/gtm';
import { GTMEvents, GTMTypes } from '../../../interfaces/GTM';

interface Props extends RouteComponentProps {
  amounts: Amounts;
  coupons: Coupon[];
  disabled?: boolean;
  loading?: boolean;
  shippingSpeed?: SHIPPING_SPEED_ENUM;
  user: User;
  cartQuote: RequestCompleteQuoteOutput[];
  cartCoupons: CartCoupon[];
  onAddCoupon: (code: string) => void;
  onRemoveCoupon: (code: string) => void;
  showCoupons?: boolean;
  onToggleShowCoupons: () => void;
}

const CartSummary: React.FunctionComponent<Props> = ({
  history,
  coupons,
  amounts,
  shippingSpeed = SHIPPING_SPEED_ENUM.NINE_DAY,
  disabled = false,
  loading = false,
  user,
  cartQuote,
  cartCoupons,
  onAddCoupon,
  onRemoveCoupon,
  showCoupons = false,
  onToggleShowCoupons,
}) => {
  const { blanks, printing, upcharge, shipping, subtotal, discount, tax, total } = amounts;
  const currentSubtotal: number = blanks + printing + upcharge;
  const preTaxOrderTotal: number = currentSubtotal + shipping - discount;
  return (
    <div className="-mx-1 px-1 lg:-mx-0 lg: mb-0 lg:px-0 flex flex-col p-relative bgc-blue-50 lg:br">
      <style jsx>{`
        .proceed-to-checkout-btn {
          bottom: -1.75rem;
        }
      `}</style>
      <div className="flex br flex-col items-stretch lg:bw-1 bc-navy-200 lg:p-1p5 lg:pb-2p5 bgc-blue-50">
        <Fields
          coupons={coupons}
          total={preTaxOrderTotal}
          subtotal={currentSubtotal}
          tax={tax}
          shipping={shipping}
          shippingCode={shippingSpeed}
          preTax={true}
          cartQuote={cartQuote}
          cartCoupons={cartCoupons}
          onAddCoupon={onAddCoupon}
          onRemoveCoupon={onRemoveCoupon}
          showCoupons={showCoupons}
          onToggleShowCoupons={onToggleShowCoupons}
        />
      </div>

      <div className="pt-2p5 lg:d-n" />
      <div className="bwt-1 -mx-1 bc-gray-200 mt-p25 lg:bwt-0 lg:d-n" />

      <div className="w-full p-absolute pin-l pin-b px-1 lg:px-1p5 proceed-to-checkout-btn">
        <Button
          className="w-full"
          onClick={(ev: React.MouseEvent) => {
            history.push(routes.app.checkout.base);
          }}
          disabled={disabled || loading}
        >
          {loading ? (
            <div>
              <Loading size="small" textClassName="d-n" paddingClassName="p-0" />
            </div>
          ) : (
            <div className="w-full flex justify-between py-p25">
              <span/>
              <div style={{ fontSize: '18px' }}>Proceed To Checkout</div>
              <i className="fs-icon-1p5 mdi mdi-arrow-right"></i>
            </div>
          )}
        </Button>
      </div>
    </div>
  );
};

export default withRouter(CartSummary);
