import * as React from "react";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { path, routes } from "inkp-routes/public";
import { MINIMUM_DTG_QUANTITY, MAX_PRODUCT_QUANTITY } from 'inkp-order-sdk/order';
import Modal from 'inkp-components/dist/Components/Modal';
import Alert from 'inkp-components/dist/Components/Alert';

import { PLACE_ORDER_ERROR_CODES } from "../../constants";

interface Props extends RouteComponentProps {
  error: PLACE_ORDER_ERROR_CODES,
  onClearError: () => void,
  onRemoveCoupon: () => void,
  onRefreshQuote: () => void,
}

class PlaceOrderError extends React.Component<Props> {
  onRedirectToCart = () => () => {
    this.props.history.push({ pathname: path(routes.app.checkout.cart) });
  }

  render () {
    const { error, onClearError, onRemoveCoupon, onRefreshQuote } = this.props;

    if (error === PLACE_ORDER_ERROR_CODES.NOT_ABLE_TO_PAY_ORDER) {
      return (
        <Modal width="464px" position="start" padding="pt-5 px-1 md:px-0" overlayColor="">
          <Alert
            alerts={[
              {
                type: 'error' as const,
                title: 'Payment failed',
                onClose: onClearError,
                content: 'Sorry, there has been a problem processing your payment. Please validate credit card information or try a different card.',
                primaryAction: 'Edit Payment Details',
                onPrimaryAction: onClearError,
              }
            ]}
          />
        </Modal>
      );
    }

    if (error === PLACE_ORDER_ERROR_CODES.INVALID_MINIMUM_DTG_QUANTITY) {
      return (
        <Modal width="464px" position="start" padding="pt-5 px-1 md:px-0" overlayColor="">
          <Alert
            alerts={[
              {
                type: 'error' as const,
                title: 'Invalid cart',
                onClose: onClearError,
                content: `A product in your cart has an order minimum of ${MINIMUM_DTG_QUANTITY} units. Please increase your total quantity to ${MINIMUM_DTG_QUANTITY} or more.`,
                primaryAction: 'Edit Cart',
                onPrimaryAction: this.onRedirectToCart(),
              }
            ]}
          />
        </Modal>
      );
    }

    if (error === PLACE_ORDER_ERROR_CODES.INVALID_MAXIMUM_QUANTITY) {
      return (
        <Modal width="464px" position="start" padding="pt-5 px-1 md:px-0" overlayColor="">
          <Alert
            alerts={[
              {
                type: 'error' as const,
                title: 'Invalid cart',
                onClose: onClearError,
                content: `A product exceeds the maximum product quantity of ${MAX_PRODUCT_QUANTITY} units. Please contact our customer service for large orders.`,
              }
            ]}
          />
        </Modal>
      );
    }

    if (error === PLACE_ORDER_ERROR_CODES.STALE_QUOTE_EXPIRED_COUPON) {
      return (
        <Modal width="464px" position="start" padding="pt-5 px-1 md:px-0" overlayColor="">
          <Alert
            alerts={[
              {
                type: 'error' as const,
                title: 'Expired coupon',
                onClose: onClearError,
                content: `The coupon code is no longer valid.`,
                primaryAction: 'Remove Code',
                onPrimaryAction: onRemoveCoupon,
                secondaryAction: 'Back to Cart',
                onSecondaryAction: this.onRedirectToCart(),
              }
            ]}
          />
        </Modal>
      );
    }

    if (error === PLACE_ORDER_ERROR_CODES.STALE_QUOTE) {
      return (
        <Modal width="464px" position="start" padding="pt-5 px-1 md:px-0" overlayColor="">
          <Alert
            alerts={[
              {
                type: 'error' as const,
                title: 'Stale cart',
                onClose: onClearError,
                content: `One or more items in your cart have changed prices.`,
                primaryAction: 'Refresh',
                onPrimaryAction: onRefreshQuote,
              }
            ]}
          />
        </Modal>
      );
    }

    return (
      <Modal width="464px" position="start" padding="pt-5 px-1 md:px-0" overlayColor="">
        <Alert
          alerts={[
            {
              type: 'error' as const,
              title: 'Checkout error',
              onClose: onClearError,
              content: 'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
            }
          ]}
        />
      </Modal>
    );
  }
};

export default withRouter(PlaceOrderError);
