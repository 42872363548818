import * as React from 'react';
import { ShippingCodeMapping, ShippingCodePercentageMapping } from '../../../constants';
import { SHIPPING_SPEED_ENUM } from 'inkp-order-sdk/types.g';
import { GetDeliverByDate } from '../../../../../util/Product';

const TEXT_FOR_FREE_SHIPPING: string = 'Free';

const formatDate = (date: string) => {
  const [day, month, monthNum] = new Date(date).toString().split(' ');
  return `${day}, ${month} ${monthNum}`;
};

const formatPrice = (price: number): string => {
  return (price / 100).toFixed(2);
};

const formatPercent = (percent: number): string => {
  if (percent === 0) {
    return TEXT_FOR_FREE_SHIPPING;
  }
  return `${percent}%`;
};

interface Props {
  active: boolean;
  shippingSpeed: SHIPPING_SPEED_ENUM;
  price: number;
  percent: number;
  isShipDate: boolean;
}

const OptionItem: React.FunctionComponent<Props> = ({ active, shippingSpeed, price, percent, isShipDate }) => {
  const dateDisplay = (sla: SHIPPING_SPEED_ENUM) => (
    <span>
      <span>{isShipDate ? 'Shipped' : 'Delivered'} by </span>
      <span className="fw-bold">{formatDate(GetDeliverByDate(new Date().toString(), sla))}</span>
    </span>
  );
  return (
    <div className="pl-1 flex justify-between w-full">
      <div className={`lg:flex items-baseline ${active ? 'color-primary' : 'color-navy-700'}`}>
        <div className="fs-md fw-bold">{ShippingCodeMapping[shippingSpeed]}</div>
        <div className="fs-xs lg:ml-p5">{dateDisplay(shippingSpeed)}</div>
      </div>
      <div className="ta-right color-green fs-md fw-extra-bold">
        {shippingSpeed !== SHIPPING_SPEED_ENUM.NINE_DAY ? (
          `$${formatPrice(price)}`
        ) : (
          ShippingCodePercentageMapping[shippingSpeed]
        )}
      </div>
    </div>
  );
};

export default OptionItem;
