const _ = {
  debounce: require('lodash/debounce'),
  clone: require('lodash/clone'),
  get: require('lodash/get'),
};
import * as React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { DataProxy } from 'apollo-cache';
import { withRouter, RouteComponentProps } from 'react-router';
import classnames from 'classnames';
import * as Sentry from '@sentry/browser';

import {
  AmountsInput,
  Cart,
  CartCouponInput,
  CartItem,
  Mutation as MutationResults,
  MutationupdateCartArgs,
  QUOTE_COUPON_RESPONSE_LEVEL_ENUM,
  Query as QueryOrderResults,
  QueryrequestCartQuoteArgs,
  QueryrequestShippingQuotesArgs,
  QuoteCouponResponse,
  RequestCompleteQuoteOutput,
  ShippingQuote,
  SHIPPING_SPEED_ENUM,
} from 'inkp-order-sdk/types.g';
import { Query as QueryProductResults, QueryproductByIdArgs, Product, ProductItem } from 'inkp-product-sdk/types.g';
import { User } from 'inkp-user-sdk/types.g';
import { SHIPPING_SPEED_ENUM_MAPPING } from 'inkp-order-sdk/order';
import { ZERO_AMOUNTS } from 'inkp-order-sdk/billing';
import { filterCouponsBySource } from 'inkp-coupon-sdk/coupon';
import { Mutation as MutationDesignResults, MutationupdateDesignArgs } from 'inkp-design-sdk/types.g';
import { routes, path } from 'inkp-routes/public';
import Loading from 'inkp-components/dist/Components/Loading';
import Alert from 'inkp-components/dist/Components/Alert';
import Button from 'inkp-components/dist/Components/Button';
import Modal from 'inkp-components/dist/Components/Modal';
import {
  MUTATION_UPDATE_CART,
  QUERY_REQUEST_CART_QUOTE,
  QUERY_REQUEST_SHIPPING_QUOTES,
  updateCurrentCart,
  joinCartProductsWithQuotes,
  getCartProductErrors,
} from '../../../util/Checkout';
import { CartItemsToCartProducts, PRODUCT_QUERY } from '../../../util/Product';
import { CartProduct } from '../../../interfaces/CartProduct';
import { Coupon, CartProductWithQuotes } from '../../../interfaces/CartCheckout';
import CantFindProduct from '../../../components/CantFindProduct';
import SizeGuide, { hasSizeGuide } from '../../../components/SizeGuide';
import CartProductItem from './CartProductItem';
import HappinessGuaranteed from '../../../components/HappinessGuaranteed';
import RelatedProducts from '../../../components/RelatedProducts';
import Summary from '../Summary';
import { COUPON_SOURCE_ENUM } from 'inkp-coupon-sdk/types.g';

// GTM helpers
import GTM from '../../../util/gtm';
import { GTMTypes } from '../../../interfaces/GTM';

const SHOW_RELATED_PRODUCTS: boolean = false;
const SHOW_CANT_FIND_PRODUCTS_BANNER: boolean = false;

const UPDATE_DESIGN_MUTATION = gql`
  mutation UpdateDesignMutation($designId: String!, $update: UpdateDesignInput!) {
    updateDesignFields(designId: $designId, update: $update) {
      id
      userNote
    }
  }
`;

class QueryProduct extends Query<QueryProductResults, QueryproductByIdArgs> {}
class QueryRequestCartQuote extends Query<QueryOrderResults, QueryrequestCartQuoteArgs> {}
class QueryRequestShippingQuotes extends Query<QueryOrderResults, QueryrequestShippingQuotesArgs> {}
class MutationUpdateCart extends Mutation<MutationResults, MutationupdateCartArgs> {}
class MutationUpdateDesign extends Mutation<MutationDesignResults, MutationupdateDesignArgs> {}

interface Props extends RouteComponentProps {
  cart: Cart;
  user: User;
  products: Product[];
}

interface State {
  showCoupon: boolean;
  sizeGuideBrand: string;
  sizeGuideSizes: string[];
  sizeGuidePrettyBrand: string;
  product: Product | null;
  showCOVID19Alert: boolean;
}

const DEBOUNCE = 500;

const filterShippingOptionsToSlowestItemSpeed = (
  items: CartItem[],
  shippingQuotes: ShippingQuote[]
): ShippingQuote[] => {
  const slowestSpeed: number = items.reduce((slowestSpeed: number, currentCartItem: CartItem) => {
    const productItem: Partial<ProductItem> | undefined = currentCartItem.product.productItem;
    if (productItem) {
      const currentCartItemSpeed: SHIPPING_SPEED_ENUM | undefined = productItem.speed;
      if (currentCartItemSpeed && SHIPPING_SPEED_ENUM_MAPPING[currentCartItemSpeed] > slowestSpeed) {
        return SHIPPING_SPEED_ENUM_MAPPING[currentCartItemSpeed];
      }
    }
    return slowestSpeed;
  }, 0);
  const filteredShippingQuotes: ShippingQuote[] = shippingQuotes.filter((shippingQuote: ShippingQuote) => {
    return SHIPPING_SPEED_ENUM_MAPPING[shippingQuote.speed] >= slowestSpeed;
  });
  return filteredShippingQuotes;
};

const addCoupon = (
  couponCode: string,
  coupons: { code: string }[],
  internalCoupons: { code: string }[]
): CartCouponInput[] => {
  const currentCoupons = coupons.map((_coupon) => {
    return { code: _coupon.code };
  });
  const isInternal = internalCoupons.find((_coupon) => _coupon.code.toLowerCase() === couponCode.toLowerCase());
  if (isInternal) {
    return currentCoupons;
  }
  return currentCoupons.concat([{ code: couponCode }]);
};

const removeCoupon = (
  couponCode: string,
  coupons: { code: string }[],
  internalCoupons: { code: string }[]
): CartCouponInput[] => {
  const currentCoupons = coupons.map((_coupon) => {
    return { code: _coupon.code };
  });
  const isInternal = internalCoupons.find((_coupon) => _coupon.code.toLowerCase() === couponCode.toLowerCase());
  if (isInternal) {
    return coupons;
  }
  return currentCoupons.filter((_coupon) => _coupon.code.toLowerCase() !== couponCode.toLowerCase());
};

class Main extends React.Component<Props, State> {
  state = {
    showCoupon: false,
    sizeGuideBrand: '',
    sizeGuideSizes: [],
    sizeGuidePrettyBrand: '',
    product: null,
    showCOVID19Alert: true,
  };

  onAddMoreProductsClick = () => (ev: React.MouseEvent<HTMLButtonElement>) => {
    const { history } = this.props;
    return history.push(path(routes.app.designTool, { productModal: 'true', showMockups: 'true' }));
  };

  onSizeGuideClick = (product: Product, brand: string, sizes: string[], prettyBrand: string) => () => {
    this.setState({ product, sizeGuideBrand: brand, sizeGuideSizes: sizes, sizeGuidePrettyBrand: prettyBrand });
  };

  onSizeGuideClose = () => () => {
    this.setState({ product: null, sizeGuideBrand: '', sizeGuideSizes: [], sizeGuidePrettyBrand: '' });
  };

  updateDesignNoteRequest = (updateDesign: any, designId: string, value: string) => {
    updateDesign({
      variables: {
        designId,
        update: {
          userNote: value,
        },
      },
    });
  };
  debounceUpdateDesignNote = _.debounce(this.updateDesignNoteRequest, DEBOUNCE);

  onUpdateDesignNote = (updateDesign: any) => (args: { designId: string; value: string }) => {
    const { designId, value } = args;
    this.debounceUpdateDesignNote(updateDesign, designId, value);
  };

  onCloseCOVIDAlert = () => () => {
    return this.setState({
      showCOVID19Alert: false,
    });
  };

  render() {
    const { cart, products, user } = this.props;
    const { showCoupon, sizeGuideBrand, sizeGuideSizes, sizeGuidePrettyBrand, product, showCOVID19Alert } = this.state;
    const { id, items, features } = cart;

    return (
      <div className="w-full h-full">
        <style jsx={true}>{`
          .fs-18 {
            font-size: 18px;
          }
        `}</style>
        <MutationUpdateCart
          mutation={MUTATION_UPDATE_CART}
          update={(cache: DataProxy, { data: { updateCart } }: any) => {
            updateCurrentCart(cache, updateCart);
            GTM.push(GTMTypes.CART);
          }}
          refetchQueries={(mutationResult) => {
            return [
              {
                query: QUERY_REQUEST_CART_QUOTE,
                variables: { cartId: id },
              },
            ];
          }}
          awaitRefetchQueries={true}
        >
          {(updateCart, { loading: updateCartLoading, error: updateCartError, data: updateCartData }) => {
            return (
              <QueryRequestCartQuote
                query={QUERY_REQUEST_CART_QUOTE}
                variables={{ cartId: id }}
                fetchPolicy="network-only"
              >
                {({ error: requestCartQuoteError, loading: requestCartQuoteLoading, data: requestCartQuoteData }) => {
                  if (requestCartQuoteError || updateCartError) {
                    console.error(requestCartQuoteError);
                    Sentry.captureMessage(`Error while requesting CartQuote for Cart with ID: ${id}`);
                    Sentry.captureException(requestCartQuoteError);
                    return (
                      <Alert
                        alerts={[
                          {
                            type: 'error',
                            title: 'Checkout error',
                            content:
                              'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
                          },
                        ]}
                      />
                    );
                  }

                  if (!requestCartQuoteData || !requestCartQuoteData.requestCartQuote) {
                    return (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loading size="large" />
                      </div>
                    );
                  }

                  const cartQuote = requestCartQuoteData.requestCartQuote || [];
                  const emptyAmounts: AmountsInput = _.clone(ZERO_AMOUNTS);
                  const totalAmounts: AmountsInput = cartQuote.reduce(
                    (amounts: AmountsInput, currentItemQuote: RequestCompleteQuoteOutput) => {
                      return {
                        blanks: amounts.blanks + currentItemQuote.amounts.blanks,
                        printing: amounts.printing + currentItemQuote.amounts.printing,
                        shipping: amounts.shipping + currentItemQuote.amounts.shipping,
                        upcharge: amounts.upcharge + currentItemQuote.amounts.upcharge,
                        subtotal: amounts.subtotal + currentItemQuote.amounts.subtotal,
                        discount: amounts.discount + currentItemQuote.amounts.discount,
                        adjustment: amounts.adjustment + currentItemQuote.amounts.adjustment,
                        tax: amounts.tax + currentItemQuote.amounts.tax,
                        total: amounts.total + currentItemQuote.amounts.total,
                      };
                    },
                    emptyAmounts
                  );
                  const couponsHash: {
                    [id: string]: Pick<QuoteCouponResponse, 'discount' | 'description' | 'source' | 'displayCode'>;
                  } = {};
                  cartQuote.map((currentItemQuote: RequestCompleteQuoteOutput) => {
                    const { couponResponse } = currentItemQuote;
                    couponResponse.forEach((currentCouponResponse: QuoteCouponResponse) => {
                      const { code, discount, level, source, description, displayCode } = currentCouponResponse;
                      if (level === QUOTE_COUPON_RESPONSE_LEVEL_ENUM.SUCCESS) {
                        let couponData = couponsHash[code];
                        couponsHash[code] = {
                          discount: couponData ? couponData.discount + discount : discount,
                          description,
                          source,
                          displayCode,
                        };
                      }
                    });
                  });
                  const coupons: Coupon[] = Object.keys(couponsHash).map((code: string) => {
                    return {
                      code,
                      displayCode: couponsHash[code] && couponsHash[code].displayCode,
                      description: couponsHash[code] && couponsHash[code].description,
                      discount: couponsHash[code] && couponsHash[code].discount,
                      source: couponsHash[code] && (couponsHash[code].source as COUPON_SOURCE_ENUM),
                    };
                  });

                  const internalCoupons = filterCouponsBySource(coupons, COUPON_SOURCE_ENUM.INTERNAL) as Coupon[];
                  const editableCoupons = cart.coupons.filter((_coupon) => {
                    return !internalCoupons.find(
                      (_internalCoupon) => _internalCoupon.code.toLowerCase() === _coupon.code.toLowerCase()
                    );
                  });
                  const cartProducts: CartProduct[] = CartItemsToCartProducts(items, products);
                  const cartProductsWithQuotes: CartProductWithQuotes[] = joinCartProductsWithQuotes(
                    cartProducts,
                    cartQuote
                  );
                  const hasCartErrors = cartProductsWithQuotes.reduce((hasError, cartProductWithQuote) => {
                    return hasError || getCartProductErrors(cartProductWithQuote).length > 0;
                  }, false);

                  // if there is any update in the cart and it changes, render will be triggered and
                  // this will push the updated data to the dataLayer
                  GTM.push(GTMTypes.CART);

                  console.log('[DEBUG] user', user);
                  console.log('[DEBUG] cart', cart);
                  console.log('[DEBUG] items', items);
                  console.log('[DEBUG] cartQuote', cartQuote);
                  console.log('[DEBUG] totalAmounts', totalAmounts);
                  console.log('[DEBUG] couponsHash', couponsHash);
                  console.log('[DEBUG] coupons', coupons);
                  console.log('[DEBUG] features', features);

                  return (
                    <React.Fragment>
                      {sizeGuideBrand && hasSizeGuide(sizeGuideBrand) && (
                        <Modal
                          onOverlayClick={this.onSizeGuideClose()}
                          onCloseClick={this.onSizeGuideClose()}
                          title="Size guide"
                          headerPadding="px-1p25 py-p75"
                          headerTextAlignment=" center"
                        >
                          <SizeGuide
                            className="fs-xs md:fs-sm m-1"
                            product={product}
                            brand={sizeGuideBrand}
                            sizes={sizeGuideSizes}
                          />
                        </Modal>
                      )}
                      <div
                        className={classnames('w-full p-p75 px-1 lg:pt-2 lg:pb-0', {
                          'lg:pb-2': !SHOW_CANT_FIND_PRODUCTS_BANNER,
                        })}
                      >
                        <div className="w-full lg:flex lg:items-start lg:w-container lg:mx-auto">
                          <div className="lg:w-6/12 lg:pr-1">
                            <div
                              className="mb-p75 lg:mb-1 flex justify-between items-center"
                              style={{ height: '36px' }}
                            >
                              <h2 className="fs-xl fw-extra-bold">Cart</h2>
                              <span className="fs-md color-navy-500 fw-bold flex items-end">{`${cartProductsWithQuotes.length} Items`}</span>
                            </div>
                            <div className="flex flex-col items-stretch pt-1 lg:pt-1p5 -my-1 lg:-my-1p5 pb-1">
                              <MutationUpdateDesign mutation={UPDATE_DESIGN_MUTATION}>
                                {(
                                  updateDesign,
                                  { loading: updateDesignLoading, error: updateDesignError, data: updateDesignData }
                                ) => (
                                  <>
                                    {cartProductsWithQuotes
                                      .reverse()
                                      .map((cartProductWithQuotes, cartProductIndex: number) => {
                                        return (
                                          <div
                                            key={`cart-product-${cartProductWithQuotes.id}`}
                                            className={classnames(
                                              {
                                                'mt-0': cartProductIndex === 0,
                                                'mt-1 lg:mt-1p5 ': cartProductIndex !== 0,
                                              },
                                              'w-full'
                                            )}
                                          >
                                            <CartProductItem
                                              cartId={cart.id}
                                              cartItems={items}
                                              cartProductWithQuotes={cartProductWithQuotes}
                                              error={_.get(getCartProductErrors(cartProductWithQuotes), '0')}
                                              onSizeGuideClick={this.onSizeGuideClick}
                                              onUpdateDesignNote={this.onUpdateDesignNote(updateDesign)}
                                            />
                                          </div>
                                        );
                                      })}
                                  </>
                                )}
                              </MutationUpdateDesign>

                              <div className="self-center">
                                <Button
                                  type="text"
                                  color="primary"
                                  icon={true}
                                  withIcon={true}
                                  mdIcon="plus-circle-outline"
                                  className="mt-p5 fw-bold"
                                  onClick={this.onAddMoreProductsClick()}
                                >
                                  <span className="self-end px-p5">Add More Products</span>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="lg:w-1/12"></div>
                          <div className="lg:w-5/12 lg:px-0 p-relative lg:p-sticky" style={{ top: '10px' }}>
                            <div className="d-n mb-1 lg:flex justify-between items-center" style={{ marginTop: '6px' }}>
                              <h2 className="fs-18 fw-extra-bold">Order Summary</h2>
                            </div>
                            <div className="lg:d-n mt-1p5 -mx-1 px-1 py-1 flex justify-between items-center bgc-blue-50">
                              <h2 className="fs-18 fw-extra-bold">Order Summary</h2>
                            </div>
                            <Summary
                              amounts={totalAmounts}
                              coupons={coupons}
                              shippingSpeed={cart.shippingDetails && cart.shippingDetails.speed}
                              disabled={true}
                              loading={requestCartQuoteLoading || updateCartLoading}
                              user={user}
                              cartCoupons={editableCoupons}
                              cartQuote={cartQuote}
                              onAddCoupon={(code) => {
                                const newCoupons = addCoupon(code, coupons, internalCoupons);
                                return updateCart({ variables: { id, data: { coupons: newCoupons } } });
                              }}
                              onRemoveCoupon={(code: string) => {
                                const newCoupons = removeCoupon(code, coupons, internalCoupons);
                                return updateCart({ variables: { id, data: { coupons: newCoupons } } });
                              }}
                              showCoupons={showCoupon}
                              onToggleShowCoupons={() => {
                                this.setState({ showCoupon: !showCoupon });
                              }}
                            />
                            <div className="w-full mt-3p5 lg:mt-4p5 pt-p25 bgc-white">
                              <HappinessGuaranteed />
                            </div>
                          </div>
                        </div>
                      </div>
                      {SHOW_RELATED_PRODUCTS && (
                        <QueryProduct query={PRODUCT_QUERY} variables={{ id: products[0].id }}>
                          {({ data: queryProductData, error: queryProductError, loading: queryProductLoading }) => {
                            if (queryProductLoading) {
                              return <Loading />;
                            }

                            const relatedProducts =
                              queryProductData &&
                              queryProductData.productById &&
                              queryProductData.productById.relatedProducts;
                            if (queryProductError || !relatedProducts || relatedProducts.length <= 0) {
                              Sentry.captureMessage(
                                `Failed to load related products for productId: ${
                                  products[0].id
                                }; err: ${JSON.stringify(queryProductError)}`
                              );
                              return <div className="pt-p5 lg:pt-2" />;
                            }

                            return (
                              <div className="w-full px-1 mt-3 lg:w-container lg:mx-auto lg:pt-6 lg:px-0 lg:mt-0">
                                <h3 className="mb-1 fw-extra-bold capitalize md:mb-1p5">recommended for you</h3>
                                <RelatedProducts
                                  relatedProducts={relatedProducts}
                                  containerClass="w-1/2 md:w-1/4"
                                  cardClass="pb-1 md:pb-0"
                                />
                              </div>
                            );
                          }}
                        </QueryProduct>
                      )}
                      {SHOW_CANT_FIND_PRODUCTS_BANNER && (
                        <div className="w-full px-1 mt-3 mb-3 lg:w-container lg:mx-auto lg:pt-4 lg:pb-6 lg:px-0 lg:mt-0">
                          <CantFindProduct />
                        </div>
                      )}
                    </React.Fragment>
                  );
                }}
              </QueryRequestCartQuote>
            );
          }}
        </MutationUpdateCart>
        {showCOVID19Alert && (
          <Modal>
            <Alert
              alerts={[
                {
                  type: 'info',
                  title: 'COVID-19 closures impact',
                  content:
                    'Due to recent closures caused by COVID-19, we are unfortunately unable to process new orders until further notice. We will keep your cart saved and will notify you as soon as we can start processing orders again. Please accept our deepest apologies for any inconvenience this may cause :(',
                  onClose: this.onCloseCOVIDAlert(),
                },
              ]}
            ></Alert>
          </Modal>
        )}
      </div>
    );
  }
}

export default withRouter(Main);
