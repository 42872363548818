import _ from 'lodash';
import * as React from 'react';
import { Query, Mutation } from 'react-apollo';

import {
  AmountsInput,
  Cart,
  CartCouponInput,
  CartItem,
  Mutation as MutationResults,
  MutationupdateCartArgs,
  QUOTE_COUPON_RESPONSE_LEVEL_ENUM,
  Query as QueryOrderResults,
  QueryrequestCartQuoteArgs,
  QueryrequestShippingQuotesArgs,
  QuoteCouponResponse,
  RequestCompleteQuoteOutput,
  ShippingQuote,
  SHIPPING_SPEED_ENUM,
  Address,
} from 'inkp-order-sdk/types.g';
import { ProductItem } from 'inkp-product-sdk/types.g';
import { ZERO_AMOUNTS } from 'inkp-order-sdk/billing';
import OptionCard from 'inkp-components/dist/Components/OptionCard';
import Loading from 'inkp-components/dist/Components/Loading';
import Alert from 'inkp-components/dist/Components/Alert';

import OptionItem from './OptionItem';
import {
  MUTATION_UPDATE_CART,
  QUERY_REQUEST_CART_QUOTE,
  QUERY_REQUEST_SHIPPING_QUOTES,
  updateCurrentCart,
  joinCartProductsWithQuotes,
  getCartProductErrors,
  getCartAmounts,
} from '../../../../../util/Checkout';
import { SHIPPING_SPEED_ENUM_MAPPING } from 'inkp-order-sdk/shipping';

const SPECIAL_STATES: { [key: string]: string } = {
  HI: 'Hawaii',
  AK: 'Alaska',
};

class QueryRequestCartQuote extends Query<QueryOrderResults, QueryrequestCartQuoteArgs> {}
class QueryRequestShippingQuotes extends Query<QueryOrderResults, QueryrequestShippingQuotesArgs> {}

interface ShippingOptionsProps {
  cart: Cart;
  address: Address;
  onSelectShipping: (speed: SHIPPING_SPEED_ENUM) => void;
  activeShippingSpeed: SHIPPING_SPEED_ENUM | undefined;
  shippingQuotes: ShippingQuote[];
}

const ShippingOptions: React.FunctionComponent<ShippingOptionsProps> = (props) => {
  const { onSelectShipping, activeShippingSpeed, shippingQuotes, cart, address } = props;
  const requiresCustomerApproval = _.get(cart, 'features.requiresCustomerApproval');
  const speicalStateDisplay = SPECIAL_STATES[address.state];

  return (
    <div>
      <h3 className="fw-extra-bold capitalize">Shipping Options</h3>
      {speicalStateDisplay && (
        <div className="mt-p5">
          Unfortunately, we do not have guaranteed delivery dates for {speicalStateDisplay}. Items may take an
          additional 1~2 weeks to be delivered.
        </div>
      )}
      {requiresCustomerApproval && (
        <div className="mt-p5">Printing won’t start without your approval, this may delay the delivery (1-2 days).</div>
      )}
      {shippingQuotes.map((shippingQuote: ShippingQuote, index: number) => {
        const active = activeShippingSpeed === SHIPPING_SPEED_ENUM[shippingQuote.speed];
        return (
          <div key={`shippingOption_${index}`} className="mt-1 cursor-pointer">
            <OptionCard
              className="mt-1 md:mt-1p5"
              borderless={true}
              active={active}
              onSelect={() => {
                if (activeShippingSpeed !== shippingQuote.speed)
                  onSelectShipping(SHIPPING_SPEED_ENUM[shippingQuote.speed]);
              }}
            >
              <OptionItem
                active={active}
                shippingSpeed={SHIPPING_SPEED_ENUM[shippingQuote.speed]}
                price={shippingQuote.cost}
                percent={shippingQuote.percent}
                isShipDate={!!speicalStateDisplay}
              />
            </OptionCard>
          </div>
        );
      })}
    </div>
  );
};

const filterShippingOptionsToSlowestItemSpeed = (
  items: CartItem[],
  shippingQuotes: ShippingQuote[]
): ShippingQuote[] => {
  const slowestSpeed: number = items.reduce((slowestSpeed: number, currentCartItem: CartItem) => {
    const productItem: ProductItem | undefined = currentCartItem.product.productItem as ProductItem;
    if (productItem) {
      const currentCartItemSpeed: SHIPPING_SPEED_ENUM | undefined = productItem.speed;
      if (currentCartItemSpeed && SHIPPING_SPEED_ENUM_MAPPING[currentCartItemSpeed] > slowestSpeed) {
        return SHIPPING_SPEED_ENUM_MAPPING[currentCartItemSpeed];
      }
    }
    return slowestSpeed;
  }, 0);
  const filteredShippingQuotes: ShippingQuote[] = shippingQuotes.filter((shippingQuote: ShippingQuote) => {
    return SHIPPING_SPEED_ENUM_MAPPING[shippingQuote.speed] >= slowestSpeed;
  });
  return filteredShippingQuotes;
};

interface Props extends Omit<ShippingOptionsProps, 'shippingQuotes'> {}

export default function ShippingOptionsProvider(props: Props) {
  const { cart, address, activeShippingSpeed, onSelectShipping } = props;

  return (
    <QueryRequestCartQuote query={QUERY_REQUEST_CART_QUOTE} variables={{ cartId: cart.id }} fetchPolicy="network-only">
      {({ error: requestCartQuoteError, loading: requestCartQuoteLoading, data: requestCartQuoteData }) => {
        if (requestCartQuoteError) {
          console.error(requestCartQuoteError);
          return (
            <Alert
              alerts={[
                {
                  type: 'error',
                  title: 'Checkout error',
                  content: 'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
                },
              ]}
            />
          );
        }

        if (!requestCartQuoteData || !requestCartQuoteData.requestCartQuote) {
          return <Loading size="medium" loadingText="Loading Shipping Options.." />;
        }

        const cartQuote = requestCartQuoteData.requestCartQuote || [];
        const totalAmounts: AmountsInput = getCartAmounts(cartQuote);

        return (
          <QueryRequestShippingQuotes query={QUERY_REQUEST_SHIPPING_QUOTES} variables={{ amounts: totalAmounts }}>
            {({
              error: requestShippingQuotesError,
              loading: requestShippingQuotesLoading,
              data: requestShippingQuotesData,
            }) => {
              if (requestShippingQuotesLoading) {
                return <Loading size="medium" loadingText="Loading Shipping Options.." />;
              }
              if (
                requestShippingQuotesError ||
                !requestShippingQuotesData ||
                !requestShippingQuotesData.requestShippingQuotes
              ) {
                return (
                  <Alert
                    alerts={[
                      {
                        type: 'error',
                        title: 'Checkout error',
                        content: 'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
                      },
                    ]}
                  />
                );
              }

              const { requestShippingQuotes } = requestShippingQuotesData;
              const filteredShippingQuotes: ShippingQuote[] = filterShippingOptionsToSlowestItemSpeed(
                cart.items,
                requestShippingQuotes
              ).filter((shippingQuote) => shippingQuote.speed === SHIPPING_SPEED_ENUM.NINE_DAY);

              return (
                <ShippingOptions
                  cart={cart}
                  address={address}
                  activeShippingSpeed={activeShippingSpeed}
                  shippingQuotes={filteredShippingQuotes}
                  onSelectShipping={onSelectShipping}
                />
              );
            }}
          </QueryRequestShippingQuotes>
        );
      }}
    </QueryRequestCartQuote>
  );
}
